import React from 'react';
import { Button, Popover, Card, Scrollable, FormLayout, Select, TextField, DatePicker } from '@shopify/polaris';

import moment from 'moment';
import { CalendarMajor } from '@shopify/polaris-icons';
import { datePickerOptions } from '../../../constants';


class CustomDatePicker extends React.Component {
    constructor(props) {
        super(props);
        const momentDate = moment();
        this.state = {
            momentDate: momentDate,
            startDate: props.startDate,
            endDate: props.endDate,
            month: momentDate.month(),
            year: momentDate.year(),
            selectedDate: {
                start: new Date(props.startDate),
                end: new Date(props.endDate)
            },
            selectedDateText: {
                start: props.startDate,
                end: props.endDate
            },
            active: false,
            RangeOption: "last30days",

        }
    }


    handleMonthChange = (month, year) => {
        this.setState({ month: month, year: year });
    }

    handleChangeRangeOption = (RangeOption) => {

        let { start, end } = this.state.selectedDate;
        let selectedDateText = this.state.selectedDateText;
        switch (RangeOption) {
            case 'today':
                start = moment().toDate()
                end = moment().toDate();
                break;
            case 'yesterday':
                start = moment().subtract(1, 'days').toDate()
                end = moment().subtract(1, 'days').toDate()
                break;
            case 'last7day':
                start = moment().subtract(6, 'days').toDate()
                end = moment().toDate();
                break;
            case 'last30days':
                start = moment().subtract(30, 'days').toDate()
                end = moment().toDate();
                break;
            case 'last90days':
                start = moment().subtract(90, 'days').toDate()
                end = moment().toDate();
                break;
            case 'lastmonth':
                start = moment().subtract(1, 'month').startOf('month').toDate()
                end = moment().subtract(1, 'month').endOf('month').toDate();
                break;
            case 'lastyear':
                start = moment().subtract(1, 'year').startOf('year').toDate()
                end = moment().subtract(1, 'year').endOf('year').toDate();
                break;
            case 'weektodate':
                start = moment().weekday(1).toDate()
                end = moment().toDate();
                break;
            case 'monthtodate':
                start = moment().startOf('month').toDate()
                end = moment().toDate();
                break;
            case 'yeartodate':
                start = moment().startOf('year').toDate()
                end = moment().toDate();
                break;
        }

        selectedDateText.start = moment(start).format('YYYY-MM-DD');
        selectedDateText.end = moment(end).format('YYYY-MM-DD');

        this.setState({
            RangeOption: RangeOption,
            selectedDate: { start: start, end: end },
            selectedDateText: selectedDateText
        });

    }

    handleChangeDate = (selectedDate) => {

        var selectedDateText = this.state.selectedDateText;

        if (moment(selectedDate.start).isValid()) {
            selectedDateText.start = moment(selectedDate.start).format('YYYY-MM-DD');
        }
        if (moment(selectedDate.end).isValid()) {
            selectedDateText.end = moment(selectedDate.end).format('YYYY-MM-DD');
        }

        this.setState({ selectedDate: selectedDate, selectedDateText: selectedDateText, RangeOption: "custom" });

    }

    handleChangeDateInput(input, value) {

        var selectedDateText = this.state.selectedDateText;
        var selectedDate = this.state.selectedDate;

        if (input === 'start') {
            selectedDateText.start = value;

            if (moment(selectedDateText.start).isValid()) {
                selectedDate.start = moment(selectedDateText.start).toDate()
            }

        }
        if (input === 'end') {
            if (moment(value).isValid()) {

                var d1 = moment(value);
                var d2 = moment(moment());
                var dff = d1.diff(d2, 'hours');

                if (dff <= 0) {
                    selectedDate.end = moment(value).toDate();
                }
            }
            selectedDateText.end = value;
        }

        this.setState({ selectedDateText: selectedDateText, selectedDate: selectedDate });

    }

    handleApplyFilter = () => {
        this.togglePopover();
        const { start, end } = this.state.selectedDate;
        const startDate = moment(start).format('YYYY-MM-DD');
        const endDate = moment(end).format('YYYY-MM-DD');
        this.setState({ startDate: moment(start), endDate: moment(end) })
        this.props.onChange(startDate, endDate);
    }


    togglePopover = () => {
        this.setState(({ active }) => {
            return { active: !active };
        });
    }

    render() {
        const { month, year, selectedDate, RangeOption, momentDate } = this.state;

        return (

            <React.Fragment>
                <Button onClick={this.togglePopover} icon={CalendarMajor}>

                    {moment(this.state.selectedDate.start).format('DDMMMYYYY') === moment(this.state.selectedDate.end).format('DDMMMYYYY')
                        ? moment(this.state.selectedDate.start).format('DD MMM YYYY')
                        : moment(this.state.selectedDate.start).format('DD MMM YYYY') + " - " + moment(this.state.selectedDate.end).format('DD MMM YYYY')
                    }</Button>
                <div style={{ maxWidth: "550px" }}>
                    <Popover
                        fluidContent={true}
                        active={this.state.active}
                        activator={<div></div>}
                        onClose={this.togglePopover}
                        fullWidth
                        icon={CalendarMajor}
                        footerContent
                    >

                        <Card>
                            <Scrollable shadow style={{ height: '400px' }}>
                                <Card.Section>
                                    <FormLayout>
                                        <FormLayout.Group>
                                            <Select label="Date range" options={datePickerOptions} onChange={this.handleChangeRangeOption} value={RangeOption} />
                                        </FormLayout.Group>
                                        <FormLayout.Group condensed>
                                            <TextField type="text" value={this.state.selectedDateText.start} autoComplete={false} label="Starting" onChange={this.handleChangeDateInput.bind(this, "start")} />
                                            <TextField type="text" value={this.state.selectedDateText.end} autoComplete={false} label="Ending" onChange={this.handleChangeDateInput.bind(this, "end")} />
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <DatePicker month={month} year={year} disableDatesAfter={momentDate.toDate()} onChange={this.handleChangeDate}
                                                onMonthChange={this.handleMonthChange} selected={selectedDate} allowRange={true} multiMonth={true}
                                            />
                                        </FormLayout.Group>
                                    </FormLayout>
                                </Card.Section>
                            </Scrollable>

                            <Card.Section>
                                <div style={{ textAlign: "right" }}>
                                    <Button onClick={this.togglePopover}>Cancel</Button>
                                    &nbsp;&nbsp;
                                    <Button primary onClick={this.handleApplyFilter}>Apply</Button>
                                </div>
                            </Card.Section>
                        </Card>
                    </Popover>
                </div>

            </React.Fragment>
        );
    }
}

export default CustomDatePicker;
