import { Badge, Button, Card, DataTable, DatePicker, EmptyState, Page, Pagination, Popover, SkeletonPage } from "@shopify/polaris";
import React, { Component } from "react";
import { connect } from "react-redux";
import Api from "../../apis/Api";
import { isEmpty } from "../../helpers/appHelpers";
import moment from 'moment';
import { defaultSelectedDaysInDatePicker, getConstantData } from "../../constants";
import CustomDatePicker from "../components/common/CustomDatePicker";

class AnalyticsLog extends Component {
    constructor(props) {
        super();
        this.state = {
            loading: true,
            topicId: props.topicId,
            channelId: props.channelId,
            startDate: moment().subtract(defaultSelectedDaysInDatePicker, 'd').format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            month: parseInt(moment().format('MM')) - 1,
            year: parseInt(moment().format('YYYY')),
            page: 0,
            size: 20,
            totalCount: 0,
            analyticsData: [],
            calenderOpen: false
        };
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.topicId !== this.props.topicId || nextProps.channelId !== this.props.channelId) {
            console.log(nextProps);
            let startDate = moment().subtract(defaultSelectedDaysInDatePicker, 'd').format("YYYY-MM-DD");
            let endDate = moment().format("YYYY-MM-DD");
            let month = parseInt(moment().format('MM')) - 1;
            let year = parseInt(moment().format('YYYY'));
            let page = 0;
            let size = 5;
            let analyticsData = [];
            this.setState({ topicId: nextProps.topicId, channelId: nextProps.channelId, startDate, endDate, month, year, analyticsData });
            this.getData(nextProps.topicId, nextProps.channelId, startDate, endDate, page, size);
        }
    }

    getData = async (topicId, channelId, startDate, endDate, page, size) => {
        const { userData } = this.props;

        let storeId = userData.id;
        const response = await Api.fetchAsyncData(
            Api.getAnalyticsData,
            storeId, { topicId, channelId, startDate, endDate, page, size });
        console.log(response);
        if (isEmpty(response) || !response.success) {

        }
        else {
            const loading = false;
            const analyticsData = response.data;
            this.setState({ analyticsData, loading });
        }

        this.getDataCount(topicId, channelId, startDate, endDate);
        console.log(response);
    }

    getDataCount = async (topicId, channelId, startDate, endDate) => {
        const { userData } = this.props;

        let storeId = userData.id;
        const response = await Api.fetchAsyncData(
            Api.getAnalyticsDataCount,
            storeId, { topicId, channelId, startDate, endDate });
        console.log(response);
        if (isEmpty(response) || !response.success) {

        }
        else {
            const loading = false;
            const totalCount = response.data;
            this.setState({ totalCount });
        }
        console.log(response);
    }


    getStart = () => {

        let { topicId, channelId, startDate, endDate, page, size } = this.state;
        this.getData(topicId, channelId, startDate, endDate, page, size);
    }

    componentDidMount() {
        this.getStart();
    }

    convertData = (analyticsData) => {
        let rows = [];
        analyticsData.map(function (singleRow, index) {
            let row = [
                singleRow.uniqueId,
                moment(singleRow.createdAt).format("YYYY-MM-DD"), ,
                singleRow.customerName,
                singleRow.customerNumber,
                singleRow.costMessage.toFixed(2),
                singleRow.status
            ];
            rows.push(row);
            console.log("index", index);
        })
        return rows;
    }

    handleMonthChange = (month, year) => {
        console.log("month", month);
        console.log("year", year);
        this.setState({ month, year });
    }

    showDateFilter = () => {
        let { calenderOpen } = this.state
        this.setState({ calenderOpen: !calenderOpen })
    }

    handleDateChange = (startDate, endDate) => {
        let page = 0;
        let size = 5;
        let { topicId, channelId } = this.state;
        this.setState({ startDate, endDate, page, size });
        this.getData(topicId, channelId, startDate, endDate, page, size);
    }


    onDateFilterUpdate = (range) => {
        let startDate = moment(range.start).format("YYYY-MM-DD");
        let endDate = moment(range.end).format("YYYY-MM-DD");
    }

    applyDateFilter = () => {
        this.setState({ calenderOpen: false })
        let { topicId, channelId, startDate, endDate, page, size } = this.state;
        this.getData(topicId, channelId, startDate, endDate, page, size);
    }

    onCloseDatePicker = () => {

    }

    onPrevious = () => {
        let { topicId, channelId, startDate, endDate, page, size } = this.state;
        page = page - 1;
        this.setState({ page });
        this.getData(topicId, channelId, startDate, endDate, page, size);
    }

    onNext = () => {

        let { topicId, channelId, startDate, endDate, page, size } = this.state;
        page = page + 1;
        this.setState({ page });
        this.getData(topicId, channelId, startDate, endDate, page, size);
    }


    render() {
        const { loading, analyticsData, channelId, topicId, startDate, endDate, month, year, totalCount, page, size } = this.state;
        let rowsData = this.convertData(analyticsData);
        let channelTopic = getConstantData(channelId, topicId);
        let totalpage = totalCount / size;
        let hasNext = false;
        let hasPrevious = false;
        let currentPage = page + 1
        if (totalpage > 1) {
            if (currentPage < totalpage) {
                hasNext = true;
                if (currentPage == 1) {
                    hasPrevious = false;
                }
                else {
                    hasPrevious = true;
                }
            }
            else {
                if (currentPage > 1) {
                    hasPrevious = true;
                }
            }
        }
        return (
            <Page
                fullWidth="true"
                breadcrumbs={[{ content: "Dashboard", url: "/dashboard" }]}
                title={channelTopic.title}
                titleMetadata={<Badge status="info">Whatsapp Message</Badge>}
                subtitle={channelTopic.subtitle}
                separator
            >
                <CustomDatePicker
                    startDate={startDate}
                    endDate={endDate}
                    onChange={this.handleDateChange}></CustomDatePicker>
                <br></br>
                <Card>
                    <DataTable
                        columnContentTypes={["text", "text", "text", "text", "float", "text"]}
                        headings={[
                            "Order",
                            "Date",
                            "Customer Name",
                            "Customer Number",
                            "Cost",
                            "Status",
                        ]}
                        rows={rowsData}
                    ></DataTable>
                    {loading ? (
                        <SkeletonPage>

                        </SkeletonPage>
                    ) : (

                        rowsData.length > 0 ? (
                            <Card.Section>
                                <Pagination
                                    hasPrevious={hasPrevious}
                                    onPrevious={this.onPrevious}
                                    hasNext={hasNext}
                                    onNext={this.onNext}
                                />
                            </Card.Section>)
                            : (
                                <Card.Section>
                                    <EmptyState
                                        heading="No Data Found"

                                    >
                                    </EmptyState>
                                </Card.Section>
                            )

                    )}
                </Card>
                <br></br>

            </Page>
        );
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsLog);
