import React, { useCallback, useState } from "react";
import { Route } from "react-router-dom";
import { Frame, AppProvider } from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import AppTopBar from "./../pages/components/layout/AppTopBar";
import Nav from "./../pages/components/layout/Nav";
import { ThemeData } from "../data/Data";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import { getJwttoken } from "../securityUtils/setJwttoken";
import jwt_decode from "jwt-decode";
import ToastMarkup from "./components/layout/ToastMarkup";
import { introTourMaxSteps } from "../constants";

const isAuthenticated = () => {

	const jwtToken = getJwttoken();
	let authenticated = false;
	if (jwtToken) {
		const decoded_jwtToken = jwt_decode(jwtToken);
		if (decoded_jwtToken.exp) {
			authenticated = true;
		}
	} else {
		authenticated = false;
	}
	return authenticated;
}



const AppLayoutRoute = ({ component: Component, ...rest }) => {

	const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

	const toggleMobileNavigationActive = useCallback(
		() =>
			setMobileNavigationActive(
				(mobileNavigationActive) => !mobileNavigationActive,
			),
		[],
	);

	const { authenticated, userData, billing, skipLogin } = rest;

	if (!skipLogin) {
		// debugger;
		if (!isAuthenticated()) {
			return <Redirect to="/login-failed" />;
		}
		if (!userData) {
			return <Redirect to="/login-failed" />;
		}
		if (
			(billing === undefined || billing == false) &&
			(userData.currentBillingStatus == null ||
				userData.currentBillingStatus == false)
		) {
			return <Redirect to="/shopify/billing" />;
		}
	}
	return (
		<Route
			render={(matchProps) => (
				<AppProvider i18n={enTranslations} theme={ThemeData}>
					<Frame
						navigation={<Nav userData={userData}></Nav>}
						showMobileNavigation={mobileNavigationActive}
						onNavigationDismiss={toggleMobileNavigationActive}
						topBar={
							<AppTopBar
								userData={userData}
								onNavigationToggle={toggleMobileNavigationActive}
							>

							</AppTopBar>
						}>
						<Component {...matchProps} {...rest} />
					</Frame>
				</AppProvider>
			)}
		/>
	);
};

export default AppLayoutRoute;
