import { takeLatest, put } from "redux-saga/effects";
import Api from "../../apis/Api";
import { setLoading, authBillingEnd } from "./authAction";

function* postBilling(data) {
	try {
		const response = yield Api.postAsync(
			Api.postAuthBillingData,
			data.payload,
			data.payload
		);
		yield put(setLoading(true));
		yield put(authBillingEnd(response));
	} catch (e) {
		yield put(setLoading(false));
	}
}

function* getAuthStoreDetails(data) {
	try {
		const response = yield Api.fetchAsync(Api.getAuthStoreData, data.payload);
		yield put(setLoading(true));
		yield put(authBillingEnd(response));
	} catch (e) {
		yield put(setLoading(false));
	}
}

function* authSaga() {
	yield takeLatest("POST_BILLING_APPROVAL_START", postBilling);
	yield takeLatest("GET_STORE_DETAIL_START", getAuthStoreDetails);
}

export default authSaga;
