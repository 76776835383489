
import { EmptyState, Frame, AppProvider, FormLayout, TextField } from "@shopify/polaris";

import React, { Component } from 'react'
import { ApiUrl } from "../constants";
import Login from "./Login";

export default class LoginFailed extends Component {
  constructor() {
    super();
    this.state = {
      shop: ""
    }
  }
  render() {
    return (
      <Login />
    )
  }
}
