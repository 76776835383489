import {
	Page,
	Layout,
	Card,
	TextStyle,
	SkeletonBodyText,
	Select,
	DatePicker,
	Popover,
	Button,
	Stack,
	FormLayout,
	DisplayText,
	Badge,
} from "@shopify/polaris";
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from 'moment';
import Api from "./../apis/Api";
import { isEmpty } from "../helpers/appHelpers";
import CustomDatePicker from "./components/common/CustomDatePicker";
import { defaultSelectedDaysInDatePicker } from "../constants";

class Dashboard extends Component {
	getFormattedDate = (d) => {
		return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
	};
	constructor(props) {
		super();

		var today = new Date();
		var endDate = moment().format("YYYY-MM-DD");
		this.state = {
			loading: true,
			channelid: 1,
			dashboardData: {},
			startDate: moment().subtract(defaultSelectedDaysInDatePicker, 'd').format("YYYY-MM-DD"),
			endDate: endDate,
			dashboradDTO: {},
		};
	}

	getWidgetsData = async (startDate, endDate) => {
		const { userData } = this.props;
		const loading = true
		this.setState({ loading })

		let storeId = userData.id;
		const response = await Api.fetchAsyncData(
			Api.getWidgetsData,
			storeId, { startDate, endDate });
		console.log(response);
		if (isEmpty(response) || !response.success) {

		}
		else {
			const loading = false;
			const dashboradDTO = response.data;
			console.log("console.log(dashboradDTO);", dashboradDTO);
			this.setState({ loading, dashboradDTO });
		}

	}

	componentDidMount() {
		this.getWidgetsData(this.state.startDate, this.state.endDate);
	}


	handleDateChange = (startDate, endDate) => {
		this.setState({ startDate, endDate });
		this.getWidgetsData(startDate, endDate);
	}

	onRangeChange = (range) => {
		var startDate = moment().subtract(range, 'd').format("YYYY-MM-DD");
		this.setState({ range, startDate });
	};

	getTotalCount = (channelId, topicId) => {
		const { dashboradDTO } = this.state;
		let count = 0, bExist = false;
		dashboradDTO.topic_channel_messages.map(function (obj) {
			if (obj.topicId == topicId && obj.channelId == channelId && !bExist) {
				count = obj.count;
				bExist = true;
			}
		});
		return count;
	}

	render() {
		const { loading, startDate, endDate } = this.state;
		const selectedDates = {
			start: this.state.startDate,
			end: this.state.endDate,
		};
		return (
			<Page
				fullWidth="true"
				title="Analytics"
				separator
			>
				<Layout>
					<Layout.Section >

						<CustomDatePicker
							startDate={startDate}
							endDate={endDate}
							onChange={this.handleDateChange}></CustomDatePicker>



						<br></br>
					</Layout.Section>
				</Layout>
				<Layout
					title="Whatsapp">
					<Layout.Section oneThird>
						<Card title={
							<DisplayText size="small">Order Placed <Badge status="info">Whatsapp Message</Badge></DisplayText>
						}>
							<Card.Section>
								{loading ? (
									<SkeletonBodyText />
								) : (
									<DisplayText size="extraLarge">{this.getTotalCount(1, 1)}</DisplayText>
								)}
							</Card.Section>
						</Card>
					</Layout.Section>
					<Layout.Section oneThird>

						<Card title={
							<DisplayText size="small">Order Shipped <Badge status="info">Whatsapp Message</Badge></DisplayText>
						}>
							<Card.Section>
								{loading ? (
									<SkeletonBodyText />
								) : (
									<DisplayText size="extraLarge">{this.getTotalCount(1, 2)}</DisplayText>
								)}
							</Card.Section>
						</Card>
					</Layout.Section>
					<Layout.Section oneThird>
						<Card title={
							<DisplayText size="small">Abandoned Cart <Badge status="info">Whatsapp Message</Badge></DisplayText>
						}>
							<Card.Section>
								{loading ? (
									<SkeletonBodyText />
								) : (
									<DisplayText size="extraLarge">{this.getTotalCount(1, 3)}</DisplayText>
								)}
							</Card.Section>
						</Card>
					</Layout.Section>
				</Layout>
				<br></br>
				<Layout
					title="Text">
					<Layout.Section oneThird>

						<Card title={
							<DisplayText size="small">Order Placed <Badge status="info">Text Message</Badge></DisplayText>
						}>
							<Card.Section>
								{loading ? (
									<SkeletonBodyText />
								) : (
									<DisplayText size="extraLarge">{this.getTotalCount(2, 1)}</DisplayText>
								)}
							</Card.Section>
						</Card>
					</Layout.Section>
					<Layout.Section oneThird>

						<Card title={
							<DisplayText size="small">Order Shipped <Badge status="info">Text Message</Badge></DisplayText>
						}>
							<Card.Section>
								{loading ? (
									<SkeletonBodyText />
								) : (
									<DisplayText size="extraLarge">{this.getTotalCount(2, 2)}</DisplayText>
								)}
							</Card.Section>
						</Card>
					</Layout.Section>
					<Layout.Section oneThird>
						<Card title={
							<DisplayText size="small">Abandoned Cart <Badge status="info">Text Message</Badge></DisplayText>
						}>
							<Card.Section>
								{loading ? (
									<SkeletonBodyText />
								) : (
									<DisplayText size="extraLarge">{this.getTotalCount(2, 3)}</DisplayText>
								)}
							</Card.Section>
						</Card>
					</Layout.Section>
				</Layout>
			</Page>
		);
	}
}
const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
