import React, { Component } from 'react'
import {
  Page,
  Layout,
  Card,
  Stack,
  ButtonGroup,
  Button,
  RadioButton,
  Checkbox,
  FormLayout,
  TextField,
  TextStyle,
  Label,
  TextContainer,
  SettingToggle,
  Select,
  ContextualSaveBar,
  Form,
  SkeletonBodyText,
} from "@shopify/polaris";
import CheckoutGuideSteps from '../../settings/display/module/CheckoutGuideSteps';


class ManualOptionIn extends Component {
  constructor(props) {
    super();
    this.state = {
      userData: props.userData,
      activeGuide: false
    }
  }
  componentDidMount() {
    let { widget_locations_list, activeGuide, storefrontWidgetApproved, storefront_widget_status, orderPage } = this.props;
    this.setState({ widget_locations_list, activeGuide, storefrontWidgetApproved, storefront_widget_status, orderPage });
  }

  //Component Will Receive
  componentWillReceiveProps(newProps) {
    if (newProps.widget_locations_list) {
      this.setState({ widget_locations_list: newProps.widget_locations_list });
    }
  }
  render() {
    let { widget_locations_list, activeGuide, storefrontWidgetApproved, storefront_widget_status, orderPage } = this.state;
    console.log("Check----", widget_locations_list);
    return (
      <div>
        <Layout.AnnotatedSection
          title="MARKETING CHECKBOX"
          description={
            <TextContainer>

              
                WhatsApp and Text Message requires users to opt-in before we are allowed to send automated messages.
              
            </TextContainer>
          }
        >
          <Card title="Recover more sales by collecting WhatsApp and Text Message opt-in from Checkout page">
            <Card.Section>
              <Stack spacing="extraTight" vertical>
              <TextStyle variation="positive">
                We have to place TCPA compliant opt-in box at your checkout that captures the phone numbers from your shoppers.
                Complete the steps in the guide and apply for approval to start collecting opt-ins from Checkout page.
                </TextStyle>
                <br></br>

                <TextStyle variation="subdued">
                  Complete the steps in the guide and We will manually check your store checkout page to verify the changes.
                </TextStyle>
              </Stack>
            </Card.Section>
            <Card.Section>
              <Stack distribution="leading">
                <ButtonGroup>
                  <Button primary onClick={() => { debugger; this.setState({ activeGuide: true }) }} size="medium">
                    Setup Checkout
                    </Button>
                  <CheckoutGuideSteps
                  userData={this.state.userData}
                   active={activeGuide} 
                   onClose={() => { this.setState({ activeGuide: !activeGuide }) }}>
                     
                   </CheckoutGuideSteps>
                </ButtonGroup>
              </Stack>
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
      </div>
    )
  }
}

export default ManualOptionIn;