
import { EmptyState, Frame, AppProvider, FormLayout, TextField, Button, Form } from "@shopify/polaris";
import Axios from "axios";
import { invalid } from "moment";

import React, { Component } from 'react'
import { ApiUrl } from "../constants";

export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      shop: "",
      invalid: false
    }

  }

  handleChange = (e) => {
    let invalid = this.isValidateShop(e);
    this.setState({ shop: e, invalid });
  }

  isValidateShop =(shop) =>{
    return !/^(?:)[a-zA-Z0-9\-]*\.myshopify\.com[\/]?/.test(shop);
  }

  handleSubmit = async() => {

    await this.handleChange(this.state.shop);
    // console.log(this.state.shop, ' invalid = ',this)
    if (!this.state.invalid) {
      const url = ApiUrl + "/store_install?shop=" + this.state.shop;
      window.location.href = url;
    }
  }

  render() {
    return (
      <div>
        <AppProvider>
          <EmptyState fullWidth
            heading="Add your myshopify url"
            // action={{ content: 'Login', url : ApiUrl +"/store_install?shop="+this.state.shop }}
            image="https://cdn.shopify.com/s/files/1/0503/4508/3065/files/f6eda9771602775955152895b69914e4.png?v=1604034224"
          >
            <br></br>
            <Form onSubmit={this.handleSubmit} fullWidth>
              <FormLayout>
                <TextField
                  requiredIndicator={true}
                  value={this.state.shop}
                  label=""
                  required={true}
                  onChange={this.handleChange}
                />
                {this.state.invalid ? <span>Please provide a valid shop name to continue.</span> : ""}

                <Button primary submit >Login</Button>
              </FormLayout>

            </Form>
          </EmptyState>
        </AppProvider>
      </div>
    )
  }
}
