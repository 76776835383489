import React, { Component } from "react";
import { Badge, DisplayText, Icon, Navigation, Stack, TextStyle, Thumbnail } from "@shopify/polaris";
import { withRouter } from 'react-router-dom';
import {
	OrdersMajor,
	HomeMajor,
	ShipmentMajor,
	AbandonedCartMajor,
	ChatMajor,
	QuestionMarkMajor,
	BillingStatementDollarMajor,
	ShareMinor,
	StoreMajor,
	CircleCancelMajor,
	SelectMinor,
	ChecklistAlternateMajor,
	ChecklistMajor,
	ProductReturnsMinor,
} from "@shopify/polaris-icons";
import { NavData } from "../../../data/Data";
import { getJwttoken } from "../../../securityUtils/setJwttoken";
import jwt_decode from "jwt-decode";
import { supportUrl } from "../../../constants";

class Nav extends Component {
	constructor() {
		super();
		this.state = {
			data: {
				navigations: NavData,

			},
			storeName: "",
			storeUrl: ""
		};
	}

	getStoreName() {
		const { userData } = this.props;
		if (userData && userData.name && userData.myShopfiyUrl) {
			const storeName = userData.name;
			const storeUrl = "https://" + this.props.userData.myShopfiyUrl + "/admin/";
			this.setState({ storeName });
			this.setState({ storeUrl });
		}
	}

	componentDidMount() {
		this.getStoreName();
		this.addNavigationEvent();
	}

	componentDidUpdate() {
		//    this.addNavigationEvent();
	}

	addNavigationEvent = () => {
		const navigationItems = document.getElementsByClassName('Polaris-Navigation__Item');
		for (var navigationItem of navigationItems) {
			navigationItem.addEventListener('click', this.changeNavigation)
		}
	}

	changeNavigation = (event) => {
		try {
			if (typeof event.target.parentElement.href !== 'undefined') {
				const url = new URL(event.target.parentElement.href);
				this.props.history.push(url.pathname);
			}
		} finally {
			event.preventDefault();
		}
	}

	render() {
		// const { navigations } = this.state.data;
		const pathname = this.props.history.location.pathname;
		const storeName = this.state.storeName;
		const storeUrl = this.state.storeUrl;
		return (
			<Navigation location="/">
				<Navigation.Section
					title={<Stack>
						<Stack.Item fill>
							<TextStyle variation="strong">General</TextStyle>
						</Stack.Item>
					</Stack>}
					separator="true"
					items={[
						{
							url: "/settings/wa/wa-chat",
							selected: pathname === '/settings/wa/wa-chat',
							label: "Whatsapp Chat",
							icon: ChatMajor,
						},
						{
							url: "/settings/wa/wa-share",
							selected: pathname === '/settings/wa/wa-share',
							label: "Whatsapp Share",
							icon: ShareMinor,
						},
					]}
				/>

				<Navigation.Section
					title="Help & Support"
					separator="true"
					items={[
						{
							selected: pathname === '/support/email',
							label: "Contact Us",
							icon: QuestionMarkMajor,
							onClick: () => { window.open(supportUrl, '_blank'); }
						},
						{
							url: "/intro",
							selected: pathname === '/intro',
							label: "Product Tour",
							icon: ProductReturnsMinor,
						},
						{
							selected: false,
							label: storeName,
							icon: StoreMajor,
							onClick: () => { window.open(storeUrl, '_blank'); }
						},
						{
							url: "/logout",
							label: "Logout",
							icon: CircleCancelMajor,
						}
					]}
				/>
			</Navigation>
		);
	}
}

export default withRouter(Nav);
