import { takeLatest, put } from "redux-saga/effects";
import Api from "../../apis/Api";
import waChatSettingsTypes from "../wa-chat/waChatSettingsTypes";
import {
  getShareConfigDataEnd,
  setLoading,
  postShareConfigDataEnd,
  getSectionPagesEnd,
} from "./../../redux/wa-share/waShareSettingsAction";
import {openToast} from "./../../redux/toast/toastActions";
import waShareSettingsTypes from "./waShareSettingsTypes";
import { toastObject } from "../../helpers/appHelpers";

let toast = toastObject;

function* fetchWAShareSettingsData(data) {
  try {
    const response = yield Api.fetchAsync(
      Api.getWAShareSettingsData,
      data.payload.storeId
    );
    yield put(setLoading(true));
    yield put(getShareConfigDataEnd(response));
  } catch (e) {
    yield put(setLoading(false));
  }
}

function* postWAShareSettingsData(data) {

  let message = "";
  let isError = false;
  try {
    isError = false;   
    message = "Settings has been saved successfully";
    const response = yield Api.postAsync(
      Api.postWAShareSettingsData,
      data.payload.storeId,
      data.payload.waShareSettingsData
    );
    yield put(postShareConfigDataEnd(response));
  } catch (e) {
    message = "There is something want wrong to save settings.";
    isError = true;
  }finally {
    toast = { ...toast, message: message, isActive: true, isError: isError }
    yield put(openToast(toast));   
    data.payload.callback();
}

}

function* fetchPagesData() {
  try {
    const response = yield Api.fetchAsync(
      Api.getSectionPagesData, 
      1
    );
    yield put(getSectionPagesEnd(response));
    } catch (e) {
      
    }
  }
  
function* waShareSettingsSaga() {
  // debugger;
  yield takeLatest(waShareSettingsTypes.GET_WA_SHARE_CONFIG_DATA_START, fetchWAShareSettingsData);
  yield takeLatest(waShareSettingsTypes.POST_WA_SHARE_CONFIG_DATA_START, postWAShareSettingsData);
  yield takeLatest(waShareSettingsTypes.GET_PAGES_START, fetchPagesData);
}

export default waShareSettingsSaga;
