export const orderConfigDisplay = {
	checkout_page_status: "Clicking “Checkout” button in Cart page",
	thank_you_page_status: "On “Thank You” page after order is placed",
};

export const defaultLanguages = {
	1: "English",
	2: "Portuguese",
	3: "Spanish",
	4: "Italian",
	5: "French",
	6: "Indonesian",
	7: "German",
	8: "Arabic",
	9: "Turkish",
	10: "Hebrew",
};

// export const TxtChannelName = "SMS";
export const TxtChannelName = "SMS";
// export const WaChannelName = "Whatsapp";
export const WaChannelName = "Whatsapp";

export const channelDetails = [{
	id: 3,
	name: "Telegram",
	editing: false,
	specialCase: false,

}, {
	id: 2,
	name: "SMS",
	editing: true,
	specialCase: false,
},
{
	id: 1,
	name: "Whatsapp",
	editing: false,
	specialCase: true,
}];


export const IsEditingAllowedInChannel = (channelName) => {
	let editing = false;
	channelDetails.map(function (obj) {
		if (obj.name == channelName && !editing) {
			editing = obj.editing;
		}
	})
	return editing;
}

export const IsSpecialCaseAllowedInChannel = (channelName) => {
	let specialCase = false;
	channelDetails.map(function (obj) {
		if (obj.name == channelName && !specialCase) {
			specialCase = obj.specialCase;
		}
	})
	return specialCase;
}

export const getChannelId = (channelName) => {
	let bExist = false;
	let channelId = 0;
	channelDetails.map(function (obj) {
		if (obj.name == channelName && !bExist) {
			channelId = obj.id;
			bExist = true;
		}
	})
	return channelId;
}


//Topic Details
export const topicDetails = [{
	id: 1,
	name: "ORDER_PLACED",

}, {
	id: 2,
	name: "ORDER_FULFILLED"
},
{
	id: 3,
	name: "ABANDONED_CART"
}];

//Get Topic Id
export const getTopicIdByTopicName = (topicName) => {
	let topicId;
	topicDetails.map(function (obj) {
		if (obj.name == topicName) {
			topicId = obj.id;
		}
	})
	return topicId;
}

export const getConstantData = (channelId, topicId) => {
	let channelTopic = {};
	switch (topicId) {
		case 1:
			channelTopic.title = "Order Message Log";
			if (channelId == 1) {
				channelTopic.subtitle = "Note: Order messages can only be sent if a customer has opted-in." +
					"If a message doesn’t get sent, it could be because (1) customer is not" +
					"registered on WhatsApp, or (2) customer did not OPT-IN to receive" +
					"communication over WhatsApp.";
			}
			else if (channelId == 2) {
				channelTopic.subtitle = "Note: Abandoned Checkout messages can only be sent if a customer has opted-in." +
					"If a message doesn’t get sent, it could be because (1) customer is not" +
					"registered on WhatsApp, or (2) customer did not OPT-IN to receive" +
					"communication over WhatsApp.";
			}
			break;
		case 2:
			channelTopic.title = "Shipping Message Log";
			if (channelId == 1) {
				channelTopic.subtitle = "Note: Order messages can only be sent if a customer has opted-in." +
					"If a message doesn’t get sent, it could be because (1) customer is not" +
					"registered on WhatsApp, or (2) customer did not OPT-IN to receive" +
					"communication over WhatsApp.";
			}
			else if (channelId == 2) {
				channelTopic.subtitle = "Note: Abandoned Checkout messages can only be sent if a customer has opted-in." +
					"If a message doesn’t get sent, it could be because (1) customer is not" +
					"registered on WhatsApp, or (2) customer did not OPT-IN to receive" +
					"communication over WhatsApp.";
			}
			break;
		case 3:
			channelTopic.title = "Abandoned Message Log";
			if (channelId == 1) {
				channelTopic.subtitle = "Note: Order messages can only be sent if a customer has opted-in." +
					"If a message doesn’t get sent, it could be because (1) customer is not" +
					"registered on WhatsApp, or (2) customer did not OPT-IN to receive" +
					"communication over WhatsApp.";
			}
			else if (channelId == 2) {
				channelTopic.subtitle = "Note: Abandoned Checkout messages can only be sent if a customer has opted-in." +
					"If a message doesn’t get sent, it could be because (1) customer is not" +
					"registered on WhatsApp, or (2) customer did not OPT-IN to receive" +
					"communication over WhatsApp.";
			}
			break;
		default:
			break;
	}
	return channelTopic;
}

export const datePickerOptions = [
	{ label: 'Custom', value: 'custom' },
	{ label: 'Today', value: 'today' },
	{ label: 'Yesterday', value: 'yesterday' },
	{ label: 'Last 7 days', value: 'last7day' },
	{ label: 'Last 30 days', value: 'last30days' },
	{ label: 'Last 90 days', value: 'last90days' },
	{ label: 'Last month', value: 'lastmonth' },
	{ label: 'Last year', value: 'lastyear' },
	{ label: 'Week to date', value: 'weektodate' },
	{ label: 'Month to date', value: 'monthtodate' },
	{ label: 'Year to date', value: 'yeartodate' },
];

export const defaultSelectedDaysInDatePicker = 30;

export const supportUrl = "https://spaceorange.freshdesk.com/support/tickets/new";

export const introTourMaxSteps = 7;
export const ApiUrl = "";
// export const ApiUrl = "http://localhost:5000";
// export const ApiUrl = "https://ee8fda440846.ngrok.io";
// export const ApiUrl = "https://wachatshareapi.spaceorange.co";