import waChatSettingsTypes from "./waChatSettingsTypes";

const initialState = {
  data: [],
  loading: true,
  error: "",
};

export default function waChatSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case waChatSettingsTypes.GET_WA_CHAT_CONFIG_DATA_START: {
      return { ...state, data: action.payload, loading: true, error: "" };
    }
    case waChatSettingsTypes.GET_WA_CHAT_CONFIG_DATA_END: {
      return { ...state, data: action.payload, loading: false };
    }
    case waChatSettingsTypes.ERROR_WA_CHAT_CONFIG_DATA: {
      return { ...state, loading: false, error: action.error };
    }

    case waChatSettingsTypes.POST_WA_CHAT_CONFIG_DATA_START: {
      return { ...state, data: action.payload, loading: false };
    }
    case waChatSettingsTypes.POST_WA_CHAT_CONFIG_DATA_END: {
      return { ...state, data: action.payload, loading: false };
    }

    default: {
      return state;
    }
  }
}
