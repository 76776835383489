import React, { Component } from 'react'
import {
  Card,
  Stack,
  RadioButton,
  Checkbox,
  FormLayout,
  TextField,
  TextStyle,
  TextContainer,
  SettingToggle,
  Button,
  Layout,
  VisuallyHidden
} from "@shopify/polaris";
import { isEmpty, toStr } from "../../../helpers/appHelpers";
import { getChannelId, IsSpecialCaseAllowedInChannel } from '../../../constants';



class ConfigSection extends Component {
  //Constructor
  constructor(props) {
    super(props);
    this.state = {
      singleCrmConfig: this.props.singleCrmConfig,
      userData: this.props.userData,
      topicId: this.props.topicId,
      is_dirty: this.props.is_dirty,
      editing_disabled: this.props.editing_disabled,
      selectedChannelName: this.props.selectedChannelName,
      errors: [],
      languages_id: 1,
    }
  }

  //Handle Change Methods
  handleChangeFormValue = (value, name, id) => {
    let { singleCrmConfig } = this.state;
    singleCrmConfig[name] = value;
    // this.setState({ singleCrmConfig });
    this.props.onChange(true, singleCrmConfig.key, singleCrmConfig);
  }
  handleBlueFormInnerArrValue = (name, mainId, innerId) => {
    let { singleCrmConfig } = this.state;
    let TemplateMessageSequence = singleCrmConfig.TemplateMessageSequence;
    let obj = TemplateMessageSequence[innerId];
    TemplateMessageSequence[innerId] = obj;

    if (name == "delay_time") {
      if (obj[name] == "") {
        obj[name] = 60;
        TemplateMessageSequence[innerId] = obj;
        this.props.onChange(true, singleCrmConfig.key, singleCrmConfig);
      }
    }
  };
  handleChangeFormInnerArrValue = (value, name, mainId, innerId) => {
    let { singleCrmConfig } = this.state;


    let TemplateMessageSequence = singleCrmConfig.TemplateMessageSequence;
    let obj = TemplateMessageSequence[innerId];


    if (name == "delay_time" && value != "") {
      value = Math.abs(value);
      if (value == 0) {
        value = obj[name];
      }
    }
    obj[name] = value;
    TemplateMessageSequence[innerId] = obj;
    // this.setState({ singleCrmConfig });
    this.props.onChange(true, singleCrmConfig.key, singleCrmConfig);
  }

  onDeleteMsgSeq = (innerId) => {
    let { singleCrmConfig } = this.state;
    let TemplateMessageSequence = singleCrmConfig.TemplateMessageSequence;
    TemplateMessageSequence.splice(innerId, 1);
    this.props.onChange(true, singleCrmConfig.key, singleCrmConfig);
  }

  //Add More MSG SEQ
  addMoreMsgSeq = (mainIndex, topicId) => {
    let { userData } = this.state;
    let { singleCrmConfig } = this.state;

    let delayTime = -1;
    let template = "";
    if (singleCrmConfig.TemplateMessageSequence[0] !== undefined) {
      let innerArrayDetailsFirst = singleCrmConfig.TemplateMessageSequence[0];
      if (innerArrayDetailsFirst.delay_time !== -1) {
        delayTime = innerArrayDetailsFirst.delay_time;
      }
      template = innerArrayDetailsFirst.template;
    }

    let channel_id = getChannelId(this.state.selectedChannelName);

    //Append Rows
    const newItem = {
      delay_time: delayTime,
      template: template,
      topic_id: topicId,
      channel_id: channel_id,
      crm_config_id: null,
      store_id: userData.id,
    };
    let TemplateMessageSequence = singleCrmConfig.TemplateMessageSequence;
    TemplateMessageSequence.push(newItem);
    // this.setState({ singleCrmConfig });
    this.props.onChange(true, singleCrmConfig.key, singleCrmConfig);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.singleCrmConfig !== this.state.singleCrmConfig) {
      this.setState({ singleCrmConfig: nextProps.singleCrmConfig });
    }
  }

  //Render Elements
  render() {

    //Get Data
    let { singleCrmConfig, topicId, errors, editing_disabled } = this.state;
    if (errors.length > 0) {
      errors = errors;
    }

    let isSpecialCase = IsSpecialCaseAllowedInChannel(this.state.selectedChannelName);
    let channel_id = getChannelId(this.state.selectedChannelName);

    //Return Data
    return (
      <div>
        {singleCrmConfig !== undefined && singleCrmConfig.topic_id === topicId && singleCrmConfig.channel_id === channel_id ? (
          <Card key={singleCrmConfig.key}>
            <Card.Section>
              <SettingToggle
                action={{
                  content: singleCrmConfig.status ? "Disable" : "Enable",
                  onAction: (e) => this.handleChangeFormValue(!singleCrmConfig.status, "status", singleCrmConfig.key)
                }}
                enabled={singleCrmConfig.status}
              >
                Messaging is
                  <TextStyle variation="strong"> {!singleCrmConfig.status ? "Disable" : "Enable"}</TextStyle>
              </SettingToggle>

              {/* Add More */}
              {singleCrmConfig.series && !isSpecialCase ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                  <Stack>
                    <Button onClick={() => { this.addMoreMsgSeq(singleCrmConfig.key, topicId) }}>Add More</Button>
                  </Stack>
                </div>
              ) : null}
              {/* End Add More */}
            </Card.Section>
            <Card.Section
            >
              {singleCrmConfig.TemplateMessageSequence.length > 0 &&
                singleCrmConfig.TemplateMessageSequence.map((templateSeq, innerMsgId) => (
                  <Card>
                    <Card.Section
                      subdued
                      key={innerMsgId}
                      actions={innerMsgId == 0 ? null : [{
                        content: 'Delete',
                        destructive: true,
                        onAction: (e) => this.onDeleteMsgSeq(innerMsgId)
                      }]}
                    >
                      {templateSeq.delay_time !== -1 ? (
                        <div>
                          <Stack alignment="center">
                            <span>Wait for</span>
                            <TextField //error={errors.delay_time}
                              onChange={e => this.handleChangeFormInnerArrValue(e * 60, "delay_time", singleCrmConfig.key, innerMsgId)}
                              value={toStr(templateSeq.delay_time / 60)}
                              suffix="Hours"
                              type="number"
                              onBlur={e => this.handleBlueFormInnerArrValue("delay_time", singleCrmConfig.key, innerMsgId)}
                              min={1}>
                            </TextField>
                            <span>after cart is abandoned, then send</span>
                          </Stack>
                          <br />
                        </div>
                      ) : null}
                      <TextField disabled={this.state.editing_disabled} multiline="true" onChange={e => this.handleChangeFormInnerArrValue(e, "template", singleCrmConfig.key, innerMsgId)} value={templateSeq.template || singleCrmConfig.defaultOrderConfMessage}>
                      </TextField>
                    </Card.Section>
                  </Card>
                ))}
            </Card.Section>
          </Card>
        ) : null
        }
      </div>
    )
  }
}

export default ConfigSection;