import { Icon, Popover, Stack, TextField, TextStyle } from "@shopify/polaris";
import { InfoMinor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import { SketchPicker } from "react-color";
import PropTypes from "prop-types";

class TextColorPicker extends Component {
  constructor() {
    super();
    this.state = {
      hexcolor: "#ff0000",
      is_visable: false
    };
  }
  componentDidMount() {

    const { color } = this.props;
    const hexcolor = color;
    if (hexcolor) {
      this.setState({ hexcolor });
    }
  }
  handleColorChange = (color, event) => {
    const { hex } = color;
    let hexcolor = hex;
    this.setState({ hexcolor });
  };
  handleChangeComplete = (color) => {
    const { hex } = color;
    let hexcolor = hex;
    this.setState({ hexcolor });
    this.props.onChange(hexcolor);
  };

  render() {
    return (
      <div>
        <Stack distribution="fill" fill>
          <Popover
            fullHeight
            preferredAlignment="left"
            active={this.state.is_visable}
            activator={<TextField
              prefix={
                <div style={{ background: this.state.hexcolor, border: "1px solid #ddd", padding: "8px", borderRadius: "3px" }} >

                </div>}
              onFocus={() => { this.setState({ is_visable: true }) }} label={this.props.title} value={this.state.hexcolor} />}
            onClose={() => { this.setState({ is_visable: false }) }}
          >
            <SketchPicker
              title={this.props.title}
              color={this.state.hexcolor}
              onChange={this.handleColorChange}
              onChangeComplete={this.handleChangeComplete}
            ></SketchPicker>
          </Popover>

        </Stack>





      </div>
    );
  }
}

TextColorPicker.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func
};

TextColorPicker.defaultProps = {
  title: "",
  color: "30BF42",
  onChange: () => { }
};


export default TextColorPicker;

