import { themeTypes } from "./themeTypes";


const initState = {
    mobileNavigationActive: false
    
}

export default function themeReducer(state = initState, action) {

    switch (action.type) {
        case themeTypes.TOGGLE_MOBILE_NAVIGATION:
            return {...state, mobileNavigationActive: action.payload.mobileNavigationActive};
        default:
            return state;
    }

}