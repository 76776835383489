import React, { Component } from 'react';
import { withRouter } from "react-router";

import {
    Page, Stack, Card, Button, EmptyState, DisplayText, ProgressBar,
    ResourceList, ResourceItem, TextStyle, Icon, TextContainer, Heading, List, SkeletonPage, Layout, SkeletonBodyText, SkeletonDisplayText
} from '@shopify/polaris';
import { CircleAlertMajor, CircleDotsMajor, CircleTickMajor } from '@shopify/polaris-icons';
import { introTourMaxSteps } from '../constants';
import Api from '../apis/Api';
import { isEmpty } from '../helpers/appHelpers';
import { setCurrentUser } from '../securityUtils/userOperation';

const totalTourSteps = 5;

class IntroductionTour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: props.userData,
            tour_id: null,
            isOpen: false,
            completedTours: [],
            enabledPoints: false,
            isLoading: true,
            restartTour: false,
            tourProgress: 0,
            skipButton: false,
        }
    }

    getStoreData = async () => {
        let { userData } = this.state;
        let myShopfiyUrl = userData.myShopfiyUrl;
        const response = await Api.fetchAsync(
            Api.getAuthStoreData,
            myShopfiyUrl
        );
        let isLoading = false;
        if (isEmpty(response) || !response.success) {

        }
        else {
            userData = response.data;
            setCurrentUser(userData);
            this.setState({ userData, isLoading });
        }
    }

    componentDidMount = () => {
        this.getStoreData();
    }

    componentWillReceiveProps(newProps) {

    }

    skipTour = async (e, url) => {
        let tourId = e;
        let { userData } = this.state;
        let storeId = userData.id;
        let skipButton = true;
        this.setState({ skipButton });

        const response = await Api.fetchAsyncData(
            Api.updateIntroTourDetails,
            storeId,
            tourId
        );
        if (isEmpty(response) || !response.success) {

        }
        else {
            userData = response.data;
            setCurrentUser(userData);
            this.setState({ userData });
        }

        window.location.href = url;

        console.log(response);
    }

    updateIntroTour = async (e, url) => {
        let tourId = e;
        let { userData } = this.state;
        let storeId = userData.id;

        if (!isEmpty(url)) {
            setTimeout(function () {
                window.location.href = url;
            }, 1000);
        }

        const response = await Api.fetchAsyncData(
            Api.updateIntroTourDetails,
            storeId,
            tourId
        );
        if (isEmpty(response) || !response.success) {

        }
        else {
            userData = response.data;
            setCurrentUser(userData);
            this.setState({ userData });
        }

        console.log(response);
    }

    render() {

        let { isLoading, skipButton } = this.state;
        return (

            <Page>
                <Card>
                    <Card.Section>
                        <DisplayText size={"small"}>Welcome to Simply QuickShare Chat</DisplayText>
                    </Card.Section>
                    <Card.Section>
                        {isLoading ? (
                            <SkeletonBodyText />
                        ) : (
                            <Card>
                                <Card.Section>
                                    <ResourceList
                                        resourceName={{ singular: 'Tours', plural: 'Tours' }}
                                        items={[
                                            {
                                                id: 1,
                                                title: 'Whatsapp Chat',
                                                discription: 'Settings for Whatsapp Chat',
                                                url: "/settings/wa/wa-chat"

                                            },
                                            {
                                                id: 2,
                                                title: 'Whatsapp Share',
                                                discription: 'Settings for Whatsapp Sharing',
                                                url: "/settings/wa/wa-share"

                                            },
                                        ]}
                                        renderItem={(item) => {
                                            const { id, url, title, discription, location } = item;
                                            const isCompleted = id <= this.state.userData.introTour ? true : false
                                            const iconColor = isCompleted ? "success" : "subdued";
                                            return (
                                                <ResourceItem
                                                    id={id}
                                                    onClick={e => this.updateIntroTour(e, url)}
                                                    media={
                                                        <Icon color={iconColor} source={CircleTickMajor}></Icon>
                                                    }
                                                    accessibilityLabel={`View details for ${title}`}
                                                >
                                                    <h3>
                                                        <TextStyle variation="strong">{title}</TextStyle>
                                                    </h3>
                                                    <div>{discription}</div>
                                                </ResourceItem>
                                            );
                                        }}
                                    />
                                </Card.Section>
                            </Card>
                        )}
                    </Card.Section>

                </Card>
            </Page >
        );
    }
}

export default IntroductionTour;