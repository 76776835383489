import React, { Component } from "react";
import AppLayoutRoute from "./pages/AppLayoutRoute";
import LoginLayoutRoute from "./pages/LoginLayoutRoute";
import store from "./redux/store";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router";
import { BrowserRouter as Router, Redirect } from "react-router-dom";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";

// display settings
import WAShareSettings from "./pages/settings/display/WAShareSettings";
import WaChatSettings from "./pages/settings/display/WAChatSettings";

// WA - CRM
// TXT - CRM
import AnalyticsLog from "./pages/crm/AnalyticsLog";

//WA - Settings
import WaOrderSettings from "./pages/settings/crm/wa/WaOrderSettings";
import WaAbandonedSettings from "./pages/settings/crm/wa/WaAbandonedSettings";

//TXT - Settings
import TxtOrderSettings from "./pages/settings/crm/txt/TxtOrderSettings";
import TxtAbandonedSettings from "./pages/settings/crm/txt/TxtAbandonedSettings";


import LoginSuccess from "./pages/LoginSuccess";
import { setJwttoken, getJwttoken } from "./securityUtils/setJwttoken";
import jwt_decode from "jwt-decode";
import LoginFailed from "./pages/LoginFailed";
import BillingPage from "./pages/BillingPage";
import BillingFailed from "./pages/BillingFailed";

import { getCurrentUser } from "./securityUtils/userOperation";
import Logout from "./pages/Logout";
import Faqs from "./pages/support/Faqs";
import WaOptInSettings from "./pages/settings/crm/wa/WaOptInSettings";
import FourZeroFour from "./pages/FourZeroFour";
import IntroductionTour from "./pages/IntroductionTour";
import { introTourMaxSteps } from "./constants";
import Support from "./pages/Support";


class App extends Component {
	constructor() {
		super();
		const userData = getCurrentUser();
		const IsAuthenticated = this.checkAuthenticated();
		this.state = {
			authenticated: IsAuthenticated,
			userData: userData,
		};
	}


	componentDidMount() { }

	checkAuthenticated = () => {
		const jwtToken = getJwttoken();
		let authenticated = false;
		if (jwtToken) {
			setJwttoken(jwtToken);
			const decoded_jwtToken = jwt_decode(jwtToken);
			if (decoded_jwtToken.exp) {
				authenticated = true;
			}
		} else {
			authenticated = false;
		}
		return authenticated;
	};
	render() {
		if (this.state.authenticated) {
		} else {
		}
		return (
			<div className="app">
				<Provider store={store}>
					<Router>
						<Switch>
							<Route exact path="/">
								<LoginLayoutRoute path="/" component={Login} />
							</Route>
							<AppLayoutRoute
								skipLogin={true}
								path="/login-success"
								component={LoginSuccess}
							/>
							<Route path="/login-failed" component={LoginFailed} />
							<Route exact path="/logout">
								<Logout />
							</Route>
							<AppLayoutRoute
								skipLogin={true}
								userData={this.state.userData}
								authenticated={this.state.authenticated}
								path="/billing-success"
								component={LoginSuccess}
							/>
							<Route path="/billing-failed" component={BillingFailed} />
							<AppLayoutRoute
								userData={this.state.userData}
								authenticated={this.state.authenticated}
								billing={true}
								path="/shopify/billing"
								component={BillingPage}
							/>
							{
								this.state.userData && this.state.userData.introTour != -1
									&& this.state.userData.introTour < introTourMaxSteps ?
									(
										<AppLayoutRoute
											userData={this.state.userData}
											authenticated={this.state.authenticated}
											path="/dashboard"
											component={IntroductionTour}
										/>
									)
									: (
										<AppLayoutRoute
											userData={this.state.userData}
											authenticated={this.state.authenticated}
											path="/dashboard"
											component={Dashboard}
										/>
									)
							}
							{/* CRM */}
							{/* WA CRM */}
							<AppLayoutRoute
								userData={this.state.userData}
								authenticated={this.state.authenticated}
								path="/pages/crm/wa/orders"
								topicId={1}
								channelId={1}
								component={AnalyticsLog}
							/>
							<AppLayoutRoute
								userData={this.state.userData}
								authenticated={this.state.authenticated}
								path="/pages/crm/wa/shipping"
								topicId={2}
								channelId={1}
								component={AnalyticsLog}
							/>
							<AppLayoutRoute
								userData={this.state.userData}
								authenticated={this.state.authenticated}
								path="/pages/crm/wa/abandoned-cart"
								topicId={3}
								channelId={1}
								component={AnalyticsLog}
							/>
							{/* TXT CRM */}
							<AppLayoutRoute
								userData={this.state.userData}
								authenticated={this.state.authenticated}
								path="/pages/crm/txt/orders"
								topicId={1}
								channelId={2}
								component={AnalyticsLog}
							/>
							<AppLayoutRoute
								userData={this.state.userData}
								authenticated={this.state.authenticated}
								path="/pages/crm/txt/shipping"
								topicId={2}
								channelId={2}
								component={AnalyticsLog}
							/>
							<AppLayoutRoute
								userData={this.state.userData}
								authenticated={this.state.authenticated}
								path="/pages/crm/txt/abandoned-cart"
								topicId={3}
								channelId={2}
								component={AnalyticsLog}
							/>
							{/* SETTINGS */}
							{/* WA - Settings */}
							<AppLayoutRoute
								userData={this.state.userData}
								authenticated={this.state.authenticated}
								path="/settings/crm/wa/orders"
								component={WaOrderSettings}
							/>
							<AppLayoutRoute
								userData={this.state.userData}
								authenticated={this.state.authenticated}
								path="/settings/crm/wa/abandoned-cart"
								component={WaAbandonedSettings}
							/>
							<AppLayoutRoute
								userData={this.state.userData}
								authenticated={this.state.authenticated}
								path="/settings/crm/wa/opt-in"
								component={WaOptInSettings}
							/>
							{/* TXT - Settings */}
							<AppLayoutRoute
								userData={this.state.userData}
								authenticated={this.state.authenticated}
								path="/settings/crm/txt/orders"
								component={TxtOrderSettings}
							/>
							<AppLayoutRoute
								userData={this.state.userData}
								authenticated={this.state.authenticated}
								path="/settings/crm/txt/abandoned-cart"
								component={TxtAbandonedSettings}
							/>
							<AppLayoutRoute
								userData={this.state.userData}
								authenticated={this.state.authenticated}
								path="/settings/wa/wa-chat"
								component={WaChatSettings}
							/>

							<AppLayoutRoute
								userData={this.state.userData}
								authenticated={this.state.authenticated}
								path="/settings/wa/wa-share"
								component={WAShareSettings}
							/>

							<AppLayoutRoute
								userData={this.state.userData}
								authenticated={this.state.authenticated}
								path="/support/faqs"
								component={Faqs}
							/>
							<AppLayoutRoute
								userData={this.state.userData}
								authenticated={this.state.authenticated}
								path="/support/email"
								component={Faqs}
							/>
							<AppLayoutRoute
								userData={this.state.userData}
								authenticated={this.state.authenticated}
								path="/intro"
								component={IntroductionTour}
							/>
							<AppLayoutRoute
								userData={this.state.userData}
								authenticated={this.state.authenticated}
								path="/support"
								component={Support}
							/>
							<AppLayoutRoute
								userData={this.state.userData}
								authenticated={this.state.authenticated}
								component={FourZeroFour}
							/>

						</Switch>
					</Router>
				</Provider>
			</div>
		);
	}
}
export default App;

var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
(function () {
	var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
	s1.async = true;
	s1.src = 'https://embed.tawk.to/608774de5eb20e09cf36df0d/1f48i120p';
	s1.charset = 'UTF-8';
	s1.setAttribute('crossorigin', '*');
	s0.parentNode.insertBefore(s1, s0);
})();
