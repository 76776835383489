import {
	Page,
	Layout,
	Card,
	TextContainer,
	CalloutCard,
	List,
} from "@shopify/polaris";
import React, { Component } from "react";
import { connect } from "react-redux";
import { authBillingStart } from "./../redux/auth/authAction";

class BillingPage extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			processing: false,
		};
	}
	componentWillReceiveProps = (nextProps) => {
		let { authData } = nextProps;
		console.log(nextProps);
		if (nextProps.error) {
			this.setState({ error: nextProps.error });
		}

		if (authData.success && authData.data) {
			console.log(nextProps.authData.data.confirmation_url);
			window.location.href = nextProps.authData.data.confirmation_url;
		}
	};
	approveCharges = () => {
		const processing = true;
		this.setState({ processing });
		let { startBilling, userData } = this.props;

		startBilling(userData.id);
		console.log("startBilling");
	};
	render() {
		return (
			<Page
				breadcrumbs={[
					{
						content: "Billing",
						url: "/dashboard",
					},
				]}
				title="Billing"
				separator
			>
				<Layout>
					<Layout.Section>
						<Card
							primaryFooterAction={{
								content: "Approve",
								onAction: this.approveCharges,
								loading: this.state.processing,
							}}
						>
							<Card.Header title="Plan"></Card.Header>
							<Card.Section title="Whatsapp Share">
								<TextContainer>
									You can use sales reports to see information about your
									customers’ orders based on criteria such as sales over time,
									by channel, or by staff.
								</TextContainer>
							</Card.Section>

							<Card.Section title="Whatsapp Chat">
								<TextContainer>
									You can use sales reports to see information about your
									customers’ orders based on criteria such as sales over time,
									by channel, or by staff.
								</TextContainer>
							</Card.Section>
							<Card.Section title="Order Message">
								<TextContainer>
									You can use sales reports to see information about your
									customers’ orders based on criteria such as sales over time,
									by channel, or by staff.
								</TextContainer>
							</Card.Section>
							<Card.Section title="Shipping Message">
								<TextContainer>
									You can use sales reports to see information about your
									customers’ orders based on criteria such as sales over time,
									by channel, or by staff.
								</TextContainer>
							</Card.Section>
						</Card>
					</Layout.Section>
				</Layout>
			</Page>
		);
	}
}

const mapStateToProps = (state) => ({
	authData: state.authData.data,
	loading: state.authData.loading,
	error: state.authData.error,
});

const mapDispatchToProps = (dispatch) => ({
	startBilling: (data) => dispatch(authBillingStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingPage);
