import React, { Component } from 'react'

export default class Support extends Component {
    componentDidMount() {

        setTimeout(function () {
            var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date(); Tawk_API.embedded = 'tawk_608774de5eb20e09cf36df0d';
            (function () {
                var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
                s1.async = true;
                s1.src = 'https://embed.tawk.to/608774de5eb20e09cf36df0d/1f4ubhnuv';
                s1.charset = 'UTF-8';
                s1.setAttribute('crossorigin', '*');
                s0.parentNode.insertBefore(s1, s0);
            })();
        }, 3000);
    }
    render() {
        return (
            <div>
                <div id='tawk_608774de5eb20e09cf36df0d'></div>
            </div>
        )
    }
}
