import { getJwttoken, setJwttoken } from "../securityUtils/setJwttoken";
import { ApiUrl } from "../constants";
import isEmpty from "is-empty";
import { openToast } from "../redux/toast/toastActions";

const api = {
	GET_WA_CHAT_CONFIG_URL: "/api/config/chat",
	POST_WA_CHAT_CONFIG_URL: "/api/config/chat",
	GET_WA_SHARE_CONFIG_URL: "/api/config/share",
	POST_WA_SHARE_CONFIG_URL: "/api/config/share",
	GET_WA_ORDER_CONFIG_URL: "/api/config/crm/wa/order",
	GET_TXT_ORDER_CONFIG_URL: "/api/config/crm/txt/order",
	POST_WA_ORDER_CONFIG_URL: "/api/config/crm/wa/order",
	GET_ORDER_MESSAGE_LOG_DATA: "/api/dashboard/order-crm",
	GET_SHIPPING_MESSAGE_LOG_DATA: "/api/dashboard/shipping-crm",
	GET_DASHBOARD_ORDER_DATA_LOG_DATA: "/api/dashboard/order/crm",
	GET_DASHBOARD_SHIPPING_DATA_LOG_DATA: "/api/dashboard/shipping/crm",
	POST_AUTH_BILLING_URL: "/api/shopify/billing",
	GET_AUTH_STORE_URL: "/api/shopify/store",
	GET_SECTION_PAGES_URL: "/api/config/pages",

	PUT_INTRO_TOUR_UPDATE: "/api/shopify/settings/tour/update",

	GET_CRM_CONFIGS_URL: "/api/config/crm/automation",
	POST_CRM_CONFIGS_URL: "/api/config/crm/automation",

	//Abandoned
	GET_WA_ABANDONED_CONFIG_URL: "/api/config/crm/wa/abandoned",
	POST_WA_ABANDONED_CONFIG_URL: "/api/config/crm/wa/abandoned",
	//Txt Msg Order Config
	GET_TXT_MSG_ORDER_CONFIG_URL: "/api/config/crm/txt/order",
	POST_TXT_MSG_ORDER_CONFIG_URL: "/api/config/crm/txt/order",
	//Txt Msg Abandoned Config
	GET_TXT_MSG_ABANDONED_CONFIG_URL: "/api/config/crm/txt/abandoned",
	POST_TXT_MSG_ABANDONED_CONFIG_URL: "/api/config/crm/txt/abandoned",
	//WA Opt In
	GET_WA_OPTIN_URL: "/api/config/crm/wa/optin",
	POST_WA_OPTIN_URL: "/api/config/crm/wa/optin",

	GET_ANALYTICS_DATA: "/api/analytics",
	GET_ANALYTICS_DATA_COUNT: "/api/analytics/count",
	GET_WIDGETS_DATA: "/api/analytics/summary",
};

export default class Api {
	static async fetchAsync(func, param) {
		const response = await func(param);
		if (!isEmpty(response)) {
			if (response.ok) {
				return await response.json();
			}
			if (response.status == 401) {
				setJwttoken(null);
				openToast("Unable to authenticate");
			}
			openToast("Unable to process");
		}
	}

	static async fetchAsyncData(func, param, data) {
		const response = await func(param, data);
		if (!isEmpty(response)) {
			if (response.ok) {
				return await response.json();
			}
			if (response.status == 401) {
				setJwttoken(null);
				openToast("Unable to authenticate");
			}
			openToast("Unable to process");
		}
	}

	static handleError = (err) => {
		console.warn(err);
		return new Response(
			JSON.stringify({
				code: 500,
				message: "Stupid network Error",
			})
		);
	};

	static async postAsync(func, param, data) {
		const response = await func(param, data);
		if (!isEmpty(response)) {
			if (response.ok) {
				return await response.json();
			}
			if (response.status == 401) {
				setJwttoken(null);
				openToast("Unable to authenticate");
			}
			openToast("Unable to process");
		}

	}

	static getAPI(url) {
		const token = getJwttoken();
		return fetch(url, {
			method: "GET",
			headers: {
				Authorization: token,
			},
		})
			.catch((error) => {
				console.log(error);
			});
	}

	static postAPI(uri, data) {
		const token = getJwttoken();
		return fetch(uri, {
			method: "post",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify(data),
		}).catch((error) => {
			console.log(error);
		});
	}

	static getWAChatSettingsData(storeId) {
		const uri = ApiUrl + api.GET_WA_CHAT_CONFIG_URL + "/" + storeId;
		return Api.getAPI(uri);
	}


	static getWAShareSettingsData(storeId) {
		const uri = ApiUrl + api.GET_WA_SHARE_CONFIG_URL + "/" + storeId;
		return Api.getAPI(uri);
	}

	static postWAChatSettingsData(storeId, data) {
		const uri = ApiUrl + api.POST_WA_CHAT_CONFIG_URL + "/" + storeId;
		data.shopify_store_id = storeId;
		return Api.postAPI(uri, data);
	}

	static postWAShareSettingsData(storeId, data) {
		const uri = ApiUrl + api.POST_WA_SHARE_CONFIG_URL + "/" + storeId;
		data.shopify_store_id = storeId;
		return Api.postAPI(uri, data);
	}

	//Order Config
	static getOrderCrmConfigData(storeId) {
		const uri = ApiUrl + api.GET_WA_ORDER_CONFIG_URL + "/" + storeId;
		return Api.getAPI(uri);
	}
	static postOrderConfigData(store_id, data) {
		const uri = ApiUrl + api.POST_WA_ORDER_CONFIG_URL + "/" + store_id;
		data.shopify_store_id = store_id;
		return Api.postAPI(uri, data);
	}

	// Generalize CRM automation

	static getCrmConfigData(storeId) {
		const uri = ApiUrl + api.GET_CRM_CONFIGS_URL + "/" + storeId;
		return Api.getAPI(uri);
	}


	static updateIntroTourDetails(store_id, data) {
		const uri = ApiUrl + api.PUT_INTRO_TOUR_UPDATE + "/" + store_id + "/" + data;
		return Api.getAPI(uri);
	}

	static postCrmConfigData(store_id, data) {
		const uri = ApiUrl + api.POST_CRM_CONFIGS_URL + "/" + store_id;
		data.shopify_store_id = store_id;
		return Api.postAPI(uri, data);
	}


	//Abandoned Cart
	static getAbandonedCrmConfigData(storeId) {
		const uri = ApiUrl + api.GET_WA_ABANDONED_CONFIG_URL + "/" + storeId;
		return Api.getAPI(uri);
	}
	static postAbandonedConfigData(store_id, data) {
		const uri = ApiUrl + api.POST_WA_ABANDONED_CONFIG_URL + "/" + store_id;
		data.shopify_store_id = store_id;
		return Api.postAPI(uri, data);
	}

	//Txt Order Config
	static getTxtMsgOrderCrmConfigData(storeId) {
		const uri = ApiUrl + api.GET_TXT_MSG_ORDER_CONFIG_URL + "/" + storeId;
		return Api.getAPI(uri);
	}
	static postTxtMsgOrderCrmConfigData(store_id, data) {
		const uri = ApiUrl + api.POST_TXT_MSG_ORDER_CONFIG_URL + "/" + store_id;
		data.shopify_store_id = store_id;
		return Api.postAPI(uri, data);
	}

	//Txt Msg Abandoned Config
	static getTxtMsgAbandonedCrmConfigData(storeId) {
		const uri = ApiUrl + api.GET_TXT_MSG_ABANDONED_CONFIG_URL + "/" + storeId;
		return Api.getAPI(uri);
	}
	static postTxtMsgAbandonedCrmConfigData(store_id, data) {
		const uri = ApiUrl + api.POST_TXT_MSG_ABANDONED_CONFIG_URL + "/" + store_id;
		data.shopify_store_id = store_id;
		return Api.postAPI(uri, data);
	}




	static postAuthBillingData(storeId, data) {
		const uri = ApiUrl + api.POST_AUTH_BILLING_URL + "/" + storeId;
		// return Api.getAPI(uri);
		// data.shopify_store_id = storeId;
		return Api.postAPI(uri, data);
	}

	static getAuthStoreData(storeId) {
		const uri = ApiUrl + api.GET_AUTH_STORE_URL + "/" + storeId;
		return Api.getAPI(uri);
	}

	static getOrderMessageLogData(storeId, { days, channelId, page, size }) {
		const uri =
			ApiUrl +
			api.GET_ORDER_MESSAGE_LOG_DATA +
			"/" +
			storeId +
			"?days=" +
			days +
			"&channelId=" +
			channelId +
			"&page=" +
			page +
			"&size=" +
			size;
		return Api.getAPI(uri);
	}

	static getShippingMessageLogData(storeId, { days, channelId, page, size }) {
		const uri =
			ApiUrl +
			api.GET_SHIPPING_MESSAGE_LOG_DATA +
			"/" +
			storeId +
			"?days=" +
			days +
			"&channelId=" +
			channelId +
			"&page=" +
			page +
			"&size=" +
			size;
		return Api.getAPI(uri);
	}

	static getDashboardOrderData(storeId, { channelId, startDate, endDate }) {
		const uri =
			ApiUrl +
			api.GET_DASHBOARD_ORDER_DATA_LOG_DATA +
			"/" +
			storeId +
			"?channelId=" +
			channelId +
			"&startDate=" +
			startDate +
			"&endDate=" +
			endDate;
		return Api.getAPI(uri);
	}

	static getDashboardShippingData(storeId, { channelId, startDate, endDate }) {
		const uri =
			ApiUrl +
			api.GET_DASHBOARD_SHIPPING_DATA_LOG_DATA +
			"/" +
			storeId +
			"?channelId=" +
			channelId +
			"&startDate=" +
			startDate +
			"&endDate=" +
			endDate;
		return Api.getAPI(uri);
	}

	static getSectionPagesData(storeId) {
		const uri = ApiUrl + api.GET_SECTION_PAGES_URL + "/" + storeId;
		return Api.getAPI(uri);
	}

	static getWAOptinSettings(storeId) {
		const uri = ApiUrl + api.GET_WA_OPTIN_URL + "/" + storeId;
		return Api.getAPI(uri);
	}
	static postWAOptinSettings(store_id, data) {
		const uri = ApiUrl + api.GET_WA_OPTIN_URL + "/" + store_id;
		data.storeId = store_id;
		return Api.postAPI(uri, data);
	}

	static getAnalyticsData(storeId, data) {
		let { topicId, channelId, startDate, endDate, page, size } = data;
		const uri = ApiUrl + api.GET_ANALYTICS_DATA + "/" + storeId
			+ "?topicId=" + topicId + "&channelId=" + channelId
			+ "&startDate=" + startDate + "&endDate=" + endDate
			+ "&page=" + page + "&limit=" + size;
		return Api.getAPI(uri);
	}

	static getAnalyticsDataCount(storeId, data) {
		let { topicId, channelId, startDate, endDate, page, size } = data;
		const uri = ApiUrl + api.GET_ANALYTICS_DATA_COUNT + "/" + storeId
			+ "?topicId=" + topicId + "&channelId=" + channelId
			+ "&startDate=" + startDate + "&endDate=" + endDate;
		return Api.getAPI(uri);
	}

	static getWidgetsData(storeId, data) {
		let { startDate, endDate } = data;
		const uri = ApiUrl + api.GET_WIDGETS_DATA + "/" + storeId
			+ "?startDate=" + startDate + "&endDate=" + endDate;
		return Api.getAPI(uri);
	}
}
