import { takeLatest, put } from "redux-saga/effects";
import Api from "../../apis/Api";
import { setLoading } from "../wa-chat/waChatSettingsAction";
import { getOrderMessageDataEnd, getShippingMessageDataEnd, getDashboardOrderDataEnd, getDashboardShippingDataEnd } from "./messagesAction";

function* getOrderMessageLogsData(data) {
    try {
      const response = yield Api.fetchAsyncData(
        Api.getOrderMessageLogData,
        1,
        data.payload,
      );
      yield put(setLoading(true));
      yield put(getOrderMessageDataEnd(response));
    } catch (e) {
      yield put(setLoading(false));
    }
}

function* getShippingMessageLogsData(data) {
    try {
      const response = yield Api.fetchAsyncData(
        Api.getShippingMessageLogData,
        1,
        data.payload
      );
      yield put(setLoading(true));
      yield put(getShippingMessageDataEnd(response));
    } catch (e) {
      yield put(setLoading(false));
    }
}

function* getDashboardOrderData(data){
  try {
    const response = yield Api.fetchAsyncData(
      Api.getDashboardOrderData,
      1,
      data.payload
    );
    yield put(setLoading(true));
    yield put(getDashboardOrderDataEnd(response));
  } catch (e) {
    yield put(setLoading(false));
  }
}

function* getDashboardShippingData(data){
  try {
    const response = yield Api.fetchAsyncData(
      Api.getDashboardShippingData,
      1,
      data.payload
    );
    yield put(setLoading(true));
    yield put(getDashboardShippingDataEnd(response));
  } catch (e) {
    yield put(setLoading(false));
  }
}


function* messagesSaga() {
    yield takeLatest( "GET_ORDER_MESSAGES_DATA_START" , getOrderMessageLogsData);
    yield takeLatest( "GET_SHIPPING_MESSAGES_DATA_START", getShippingMessageLogsData);
    yield takeLatest( "GET_DASHBOARD_ORDER_DATA_START", getDashboardOrderData);
    yield takeLatest( "GET_DASHBOARD_SHIPPING_DATA_START", getDashboardShippingData);
}
  
export default messagesSaga;