import { all, call } from "redux-saga/effects";
import messagesSaga from "./messages/messagesSaga";
import orderConfigSaga from "./order-config/orderConfigSaga";
import abandonedConfigSaga from "./abandoned-config/abandonedConfigSaga";

/* WA-Chat App Saga */
import waChatSettingsSaga from "./wa-chat/waChatSettingsSaga";
/* WA-share App Saga */
import waShareSettingsSaga from "./wa-share/waShareSettingsSaga";
/* Auth Saga */
import authSaga from "./auth/authSaga";

export function* rootSaga() {
	yield all([
		call(waChatSettingsSaga),
		call(waShareSettingsSaga),
		call(orderConfigSaga),
		call(abandonedConfigSaga),
		call(messagesSaga),
		call(authSaga),
	]);
}
