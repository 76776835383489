import {
	POST_BILLING_APPROVAL_START,
	POST_BILLING_APPROVAL_END,
	GET_STORE_DETAIL_START,
	GET_STORE_DETAIL_END,
} from "./authTypes";

const initialState = {
	data: [],
	loading: true,
	error: "",
};

export default function authReducer(state = initialState, action) {
	switch (action.type) {
		case POST_BILLING_APPROVAL_START: {
			return { ...state, data: action.payload, loading: true };
		}
		case POST_BILLING_APPROVAL_END: {
			return { ...state, data: action.payload, loading: false };
		}
		case GET_STORE_DETAIL_START: {
			return { ...state, data: action.payload, loading: true };
		}
		case GET_STORE_DETAIL_END: {
			return { ...state, data: action.payload, loading: false };
		}
		default: {
			return state;
		}
	}
}
