import React, { Component } from "react";
import {
    Page,
    Layout,
    Card,
    Checkbox,
    TextContainer,
    ContextualSaveBar,
    Form,
    SkeletonBodyText,
    Badge,
    Toast,
} from "@shopify/polaris";
import { connect } from "react-redux";
import SupportBanner from "../../../components/common/SupportBanner";
import { getOrderConfigDataStart, postOrderConfigDataStart } from "../../../../redux/order-config/orderConfigAction";
import { IsEditingAllowedInChannel, orderConfigDisplay, getTopicIdByTopicName, WaChannelName } from "../../../../constants";
import ManualOptionIn from "../../../components/common/ManualOptionIn";
import ConfigSection from "../../../components/pagecomponent/ConfigSection";
import Api from "../../../../apis/Api";
import { isEmpty } from "../../../../helpers/appHelpers";



class WaOptInSettings extends Component {
    constructor(props) {
        super();
        this.state = {
            userData: props.userData,
            activeGuide: false,
            orderPage: true,
            selectedChannelName: WaChannelName,
            errors: [],
            WhatsAppOptinConfigDTO: {},
            loading: true,
            isSaving: false,
            is_dirty: false,
            isDiscarding: false,
            //Toast Message
            toast: {
                isError: false,
                isActive: false,
                message: ""
            },
        };
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {

    }

    render() {
        const { WhatsAppOptinConfigDTO, toast } = this.state;

        const widget_locations = orderConfigDisplay;
        const { loading } = this.props;



        let storefrontWidgetApproved = false;
        let storefrontWidgetStatus = false;
        let widget_locations_list;
        //Check Exist WhatsApp Option
        if (WhatsAppOptinConfigDTO) {
            storefrontWidgetApproved = WhatsAppOptinConfigDTO.storefront_widget_approved;
            storefrontWidgetStatus = WhatsAppOptinConfigDTO.storefrontWidgetStatus;

            if (this.state.orderPage) {
                console.log("widget_location");
            }
            else {
                delete widget_locations["thank_you_page_status"];
            }

            //Widget Location List
            widget_locations_list =
                widget_locations &&
                Object.keys(widget_locations).map((keyname) => {
                    return (
                        <Checkbox
                            key={keyname}
                            label={widget_locations[keyname]}
                            checked={this.state.WhatsAppOptinConfigDTO[keyname]}
                            onChange={(value) => { this.handleChange(keyname, value) }}
                        />
                    )
                }
                );
        }

        return (
            <Page
                fullWidth="true"
                breadcrumbs={[{ content: "Dashboard", url: "/dashboard" }]}
                title="Manual Opt In"
                subtitle="Settings for Manual Opt In"
                titleMetadata={<Badge status="info">Whatsapp and Text Message </Badge>}
                separator

            >

                <Form onSubmit={this.handleSubmit}>

                    <Layout>
                        <ManualOptionIn
                            userData={this.state.userData}
                            widget_locations_list={widget_locations_list}
                            activeGuide={this.state.activeGuide}
                            storefrontWidgetApproved={storefrontWidgetApproved}
                            storefront_widget_status={storefrontWidgetStatus}
                            orderPage={this.state.orderPage}></ManualOptionIn>
                        <SupportBanner></SupportBanner>
                    </Layout>
                </Form>
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    // orderConfigData: state.orderConfigData.data,
    // loading: state.orderConfigData.loading,
    // error: state.orderConfigData.error,
    toast: state.toast,
});

const mapDispatchToProps = (dispatch) => ({
    // getStart: (data) => dispatch(getOrderConfigDataStart(data)),
    // postStart: (data) => dispatch(postOrderConfigDataStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WaOptInSettings);