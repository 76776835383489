import { Button, Card, Collapsible, DescriptionList, DisplayText, Icon, Modal, ResourceItem, ResourceList, Stack, TextStyle } from '@shopify/polaris'
import { DuplicateMinor, MaximizeMajor, MaximizeMinor } from '@shopify/polaris-icons';
import React, { Component } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import CopyToClipboardElements from './CopyToClipboardElements';

export default class CustomVariables extends Component {
    constructor(props) {
        super();
        this.state = {
            open: false,
            copied:false
        }
    }
    render() {
        
        return (
            <Card
                primaryFooterAction={{
                    content: 'Review Variable',
                    onAction: () =>
                        this.setState({ "open": !this.state.open })
                }}
                    >
                <Card.Section>
                    <TextStyle variation="positive">
            Variable will be used for replacing with actual data
             
                    </TextStyle>

                </Card.Section>
                <Modal
                    open={this.state.open}
                    title={"Variables"}
                    onClose={e => this.setState({ "open": false, copied: false })}>
                    <ResourceList
                        resourceName={{ singular: 'Variables', plural: 'Variable' }}
                        items={[
                            {
                                term: '${customerName}',
                                description:
                                    'Customer Name',
                            },
                            {
                                term: '${totalPrice}',
                                description:
                                    'Total Price',
                            },
                            {
                                term: '${storeName}',
                                description:
                                    'Store Name',
                            },
                            {
                                term: '${discount_10_percentage}',
                                description:
                                    'Discount with percentage or fixed',
                            },
                            {
                                term: '${orderUrl}',
                                description:
                                    'Order Status URL or Abandoned cart url',
                            },
                            {
                                term: '${storeUrl}',
                                description:
                                    'Store URL',
                            }
                        ]}
                        renderItem={(item) => {
                            const { term, description } = item;

                            return (
                                <ResourceItem
                                >
                                    <CopyToClipboard text={term}
                                        onCopy={() => this.setState({ copied: true })}>
                                        <h3>
                                            <Stack>
                                                <Stack.Item fill>
                                                    <TextStyle>
                                                        {term}
                                                        <br>

                                                        </br>
                                                        {description}
                                                    </TextStyle>
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <Icon source={DuplicateMinor}
                                                        color="highlight">
                                                    </Icon>
                                                </Stack.Item>

                                            </Stack>
                                        </h3>

                                    </CopyToClipboard>

                                </ResourceItem>
                            );
                        }}
                    />
                </Modal>
            </Card >
        )
    }
}
