import { useHistory, useParams } from "react-router-dom";
import { getJwttoken, setJwttoken } from "../securityUtils/setJwttoken";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAuthStoreDetailStart } from "../redux/auth/authAction";
import jwt_decode from "jwt-decode";
import { setCurrentUser } from "../securityUtils/userOperation";
import { Card, Layout, Page, SkeletonBodyText } from "@shopify/polaris";

class LoginSuccess extends Component {
	constructor() {
		super();
		// debugger;
	}
	componentDidMount() {
		const params = window.location.pathname.split("/");
		if (params.length > 2) {
			let token = decodeURIComponent(params[2]);
			// const params = new URLSearchParams(window.location.search);
			// let token = params.get('token');
			if (token) {
				token = token.replace(":Bearer", "Bearer");
			} else {
				token = getJwttoken();
			}
			if (token) {
				setJwttoken(token);
				const decoded_jwtToken = jwt_decode(token);
				console.log(decoded_jwtToken);
				setCurrentUser(null);
				let { getAuthStoreDetails } = this.props;
				getAuthStoreDetails(decoded_jwtToken.username);
			}
		}
		else {
			let token = getJwttoken();
			if (token) {
				setJwttoken(token);
				const decoded_jwtToken = jwt_decode(token);
				console.log(decoded_jwtToken);
				setCurrentUser(null);
				let { getAuthStoreDetails } = this.props;
				getAuthStoreDetails(decoded_jwtToken.username);
			}
		}
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.error) {
			this.setState({ error: nextProps.error });
		}
		if (nextProps.authData && nextProps.authData.data) {
			setCurrentUser(nextProps.authData.data);
			window.location.href = "/dashboard";
		}
	}
	render() {
		return (
			<Page separator>
				<Layout>
					<Layout.Section>
						<SkeletonBodyText />
					</Layout.Section>
				</Layout>
			</Page>
		);
	}
}

const mapStateToProps = (state) => ({
	authData: state.authData.data,
	loading: state.authData.loading,
	error: state.authData.error,
});

const mapDispatchToProps = (dispatch) => ({
	getAuthStoreDetails: (user) => dispatch(getAuthStoreDetailStart(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginSuccess);
