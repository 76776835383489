const setCurrentUser = (user) => {
	if (user) {
		localStorage.setItem("current_user", JSON.stringify(user));
	} else {
		localStorage.removeItem("current_user");
	}
};

const getCurrentUser = () => {
	let current_user = localStorage.getItem("current_user");
	if (current_user == null) return null;
	else return JSON.parse(localStorage.getItem("current_user"));
};

export { setCurrentUser, getCurrentUser };
