import {
  Page,
  Layout,
  Card,
  Stack,
  Button,
  RadioButton,
  Checkbox,
  FormLayout,
  TextField,
  RangeSlider,
  TextStyle,
  SettingToggle,
  SkeletonBodyText,
  ContextualSaveBar,
  Form,
} from "@shopify/polaris";
import React, { Component } from "react";
import PageSelection from "../../components/common/PageSelection";
import SupportBanner from "../../components/common/SupportBanner";
import { connect } from "react-redux";
import { getDataStart, postDataStart } from "../../../redux/wa-chat/waChatSettingsAction";
import AgentPopup from "./module/AgentPopup";
import ChatCalloutCard from "./module/ChatCalloutCard";
import TextColorPicker from "./module/TextColorPicker";

const mobilePositions = [
  { lable: "None", value: "none" },
  { lable: "Left", value: "left" },
  { lable: "Right", value: "right" }
]

const desktopPositions = [
  { lable: "None", value: "none" },
  { lable: "Left", value: "left" },
  { lable: "Right", value: "right" }
]
class WaChatSettings extends Component {
  constructor() {
    super();
    this.state = {
      settings: {
        status: false,
        callout_card_display: false,
        callout_card_text: "",
        card_delay: "10",
        desktop_edge: 20,
        desktop_height: 10,
        desktop_pos: "right",
        mobile_edge: 20,
        mobile_height: 10,
        mobile_pos: "right",
        custom_desktop_height: 20,
        custom_mobile_height: 10,
        custom_desktop_edge: 20,
        custom_mobile_edge: 10,
        selected_page: [],
        agent_name: "",
        agent_wating_text: "",
        agent_greeting_text: "",
        agent_send_button_text: "",
        agent_phone_number: "",
        color_type: "",
        bg_color: "",
        gradient_color: "",
        icon_or_text_color: "",
        button_text_color: ""

      },


      error: "",
      is_loading: false,
      is_dirty: false,
    };
  }
  componentDidMount() {
    let { getStart, userData } = this.props;
    const storeId = userData.id;
    getStart({ storeId });
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.error) {
      this.setState({ error: nextProps.error });
    }
    if (nextProps.waChatData && nextProps.waChatData.data) {
      const is_dirty = false;
      const {
        status,
        callout_card_display,
        callout_card_text,
        agent_help_text,
        msg_description,
        short_description,
        card_delay,
        mobile_pos,
        desktop_pos,
        mobile_height,
        desktop_height,
        mobile_edge,
        desktop_edge,
        custom_chat_button_height_status,
        custom_mobile_height,
        custom_desktop_height,
        avatars_url,
        agent_name,
        agent_wating_text,
        agent_greeting_text,
        agent_send_button_text,
        agent_phone_number,
        color_type,
        bg_color,
        gradient_color,
        icon_or_text_color,
        button_text_color,
        selected_page
      } = nextProps.waChatData.data;
      const pages = [];

      nextProps.waChatData.data.pages.map(function (item) {
        pages.push({ value: item.id, label: item.name },)
      });


      let { settings } = this.state;
      settings = {
        ...settings,
        status: status,
        callout_card_display: callout_card_display,
        callout_card_text: callout_card_text,
        agent_help_text: agent_help_text,
        msg_description: msg_description,
        short_description: short_description,
        card_delay: card_delay,
        mobile_pos: mobile_pos,
        desktop_pos: desktop_pos,
        mobile_height: mobile_height,
        desktop_height: desktop_height,
        mobile_edge: mobile_edge,
        desktop_edge: desktop_edge,
        custom_chat_button_height_status: custom_chat_button_height_status,
        custom_mobile_height: custom_mobile_height,
        custom_desktop_height: custom_desktop_height,
        avatars_url: avatars_url,
        pages: pages,
        agent_name: agent_name,
        agent_wating_text: agent_wating_text,
        agent_greeting_text: agent_greeting_text,
        agent_send_button_text: agent_send_button_text,
        agent_phone_number: agent_phone_number,
        color_type: color_type,
        bg_color: bg_color,
        gradient_color: gradient_color,
        icon_or_text_color: icon_or_text_color,
        button_text_color: button_text_color,
        selected_page: selected_page
      }


      this.setState({ settings: settings });

    }

  }

  getValue = (value, name) => {
    switch (name) {
      case "mobile_height":
      case "mobile_pos":
      case "desktop_pos":
      case "mobile_height":
      case "desktop_height":
      case "mobile_edge":
      case "desktop_edge":
        if (value == "")
          value = 0;
        break;
    }
    return value;
  }

  handleChanageFormValue = (value, name) => {
    let { settings } = this.state;
    value = this.getValue(value, name)
    settings = { ...settings, [name]: value }
    this.setState({ settings: settings });
    this.makeDirty()
  }


  handleChatCalloutChanage = (values) => {
    const { callout_card_display, callout_card_text, card_delay } = values;
    let { settings } = this.state;
    settings = {
      ...settings,
      callout_card_display: callout_card_display,
      callout_card_text: callout_card_text,
      card_delay: card_delay
    }
    this.setState({ settings: settings });
    this.makeDirty()
  }

  makeDirty = () => {
    this.setState({ is_dirty: true })
  }

  handleChangePageSection = (selected) => {
    this.makeDirty();
    let { settings } = this.state;
    settings = { ...settings, selected_page: selected }
    this.setState({ settings: settings });
  }

  handleSubmit = (_event) => {

    if (this.state.is_dirty) {
      this.setState({ is_loading: !this.state.is_loading });
      const postSettingData = this.state.settings;
      let { postStart, userData } = this.props;
      const storeId = userData.id;
      postStart({
        postSettingData,
        storeId,
        callback: () => {
          this.setState({ is_loading: false, is_dirty: false });
        }
      });
    }
  };

  isGradient = (color) => {
    return color === "gradient";
  };

  toString = (val) => {
    if (val || val == 0) { return val.toString() } else { return ""; }
  }

  onDiscard = () => {
    let { getStart, userData } = this.props;
    const storeId = userData.id;
    getStart({ storeId });
    this.setState({ is_dirty: false });
  }

  render() {

    // debugger;
    const { loading } = this.props;

    const contentStatus = this.state.settings.status ? "Disable" : "Enable";
    const textStatus = this.state.settings.status ? "Enabled" : "Disabled";
    const { mobile_pos, desktop_pos, custom_chat_button_height_status } = this.state.settings;

    return (
      <Page
        fullWidth="true"
        breadcrumbs={[{ content: "Dashboard", url: "/dashboard" }]}
        title="WhatsApp Chat"
        subtitle="Settings for WhatsApp chat"
        separator
      >
        {loading ? (
          <Layout>
            <Layout.AnnotatedSection title={<SkeletonBodyText />}>
              <Card sectioned>
                <SkeletonBodyText />
              </Card>
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection title={<SkeletonBodyText />}>
              <Card sectioned>
                <SkeletonBodyText />
              </Card>
            </Layout.AnnotatedSection>
          </Layout>
        ) : (
          <Layout>
            <Form onSubmit={this.handleSubmit}>
              {this.state.is_dirty ?
                <ContextualSaveBar
                  message="Unsaved changes"
                  saveAction={{
                    content: "Save Settings",
                    onAction: this.handleSubmit,
                    loading: this.state.is_loading,
                    disabled: !this.state.is_dirty,
                  }}
                  discardAction={{
                    onAction: () => {
                      this.onDiscard();
                      this.setState({ is_dirty: !this.state.is_dirty })
                    },
                  }}
                />
                : null}

              <Layout.AnnotatedSection
                title="Chat"
                description="Enable or Disable."
              >
                <SettingToggle
                  action={{
                    content: contentStatus,
                    onAction: () => (this.handleChanageFormValue(!this.state.settings.status, "status")),
                  }}
                  enabled={this.state.settings.status}
                >
                  Customers will be able to see share button on respective pages
                  :<TextStyle variation="strong"> {textStatus}</TextStyle>
                </SettingToggle>


              </Layout.AnnotatedSection>



              <Layout.AnnotatedSection
                title="Basic Settings"
                description="Greet your store visitors with with customised title and help text in the greetings widget."
              >
                <Card sectioned>
                  <Stack vertical>
                    <FormLayout>
                      <TextField
                        label="Agent name"
                        value={this.state.settings.agent_name}
                        onChange={(val) => this.handleChanageFormValue(val, "agent_name")}
                      />
                      <TextField
                        label="Agent waiting title"
                        value={this.state.settings.agent_wating_text}
                        onChange={(val) => this.handleChanageFormValue(val, "agent_wating_text")}
                      />
                      <TextField
                        label="Agent greeting text"
                        value={this.state.settings.agent_greeting_text}
                        onChange={(val) => this.handleChanageFormValue(val, "agent_greeting_text")}
                      />
                      <TextField
                        label="Agent send button text"
                        value={this.state.settings.agent_send_button_text}
                        onChange={(val) => this.handleChanageFormValue(val, "agent_send_button_text")}
                      />

                      <TextField
                        label="Phone number"
                        type="number"
                        max="12"
                        value={this.state.settings.agent_phone_number}
                        onChange={(val) => this.handleChanageFormValue(val, "agent_phone_number")}
                        helpText="We’ll use this phone number for connecting your customer with you on Whatsapp. Please share WhatsApp phone number (should not start with +)"
                      />


                    </FormLayout>
                  </Stack>
                </Card>
              </Layout.AnnotatedSection>

              <Layout.AnnotatedSection
                title="Button Design"
                description="Setup chat button design"
              >
                <Card
                  sectioned
                  title={
                    <TextStyle>
                      You are currently setting custom colours
                    </TextStyle>
                  }
                >
                  <Stack vertical>
                    <FormLayout>
                      <TextStyle>Background colour style</TextStyle>
                      <Stack>
                        <RadioButton
                          key={"single"}
                          name="color_type"
                          label="Single colour"
                          value="single"
                          checked={this.state.settings.color_type === "single"}
                          onChange={() => this.handleChanageFormValue("single", "color_type")}
                        ></RadioButton>
                        <RadioButton
                          key={"gradient"}
                          name="color_type"
                          label="Gradient of two colours"
                          value="gradient"
                          checked={this.state.settings.color_type === "gradient"}
                          onChange={() => this.handleChanageFormValue("gradient", "color_type")}
                        ></RadioButton>
                      </Stack>
                      <Stack distribution="fillEvenly">
                        <div>
                          <TextColorPicker
                            title={"Background colour 1"}
                            color={this.state.settings.bg_color}
                            onChange={(val) => this.handleChanageFormValue(val, "bg_color")}
                          ></TextColorPicker>
                        </div>
                        <div>
                          {this.isGradient(this.state.settings.color_type) ? (

                            <TextColorPicker
                              title={"Background colour 2 (for gradient)"}
                              color={this.state.settings.gradient_color}
                              onChange={(val) => this.handleChanageFormValue(val, "gradient_color")}
                            ></TextColorPicker>
                          ) : (
                            ""
                          )}
                        </div>
                      </Stack>
                      <Stack distribution="fillEvenly">
                        <div>
                          <TextColorPicker
                            title={"Icon colour"}
                            color={this.state.settings.icon_or_text_color || '#fff'}
                            onChange={(val) => this.handleChanageFormValue(val, "icon_or_text_color")}
                          ></TextColorPicker>
                        </div>
                        <div>
                          <TextColorPicker
                            title={"Button text colour"}
                            color={this.state.settings.button_text_color || '#fff'}
                            onChange={(val) => this.handleChanageFormValue(val, "button_text_color")}
                          ></TextColorPicker>
                        </div>
                      </Stack>
                    </FormLayout>
                  </Stack>
                </Card>

              </Layout.AnnotatedSection>
              <Layout.AnnotatedSection
                title="Display Position"
                description="Change the display position of the chat button to best suit your store’s design."
              >
                <Card sectioned>
                  <Stack vertical>
                    <FormLayout>
                      <TextStyle variation="strong">Button Position</TextStyle>
                      <Stack distribution="fill">
                        <Card>
                          <Card.Section subdued >
                            <TextStyle variation="strong">Mobile</TextStyle>
                          </Card.Section>
                          <Card.Section >

                            <Stack>
                              {mobilePositions.map(function (mobilePoistion, index) {
                                return (<RadioButton
                                  key={index}
                                  name="mobile_pos"
                                  label={mobilePoistion.lable}
                                  checked={mobile_pos === mobilePoistion.value}
                                  onChange={() => this.handleChanageFormValue(mobilePoistion.value, "mobile_pos")}
                                />)
                              }, this)}

                            </Stack>
                            <br />
                            <Stack vertical>
                              <TextField
                                label="Height offset"
                                suffix="px"
                                type="number"
                                value={this.toString(this.state.settings.mobile_height)}
                                onChange={(val) => this.handleChanageFormValue(val, "mobile_height")}
                              />
                              <TextField
                                label="Edge offset"
                                type="number"
                                suffix="px"
                                value={this.toString(this.state.settings.mobile_edge)}
                                onChange={(val) => this.handleChanageFormValue(val, "mobile_edge")}
                              />

                            </Stack>
                          </Card.Section>
                        </Card>


                        <Card>
                          <Card.Section subdued >
                            <TextStyle variation="strong">Desktop</TextStyle>
                          </Card.Section>
                          <Card.Section >

                            <Stack>
                              {desktopPositions.map(function (desktopPoistion, index) {
                                return (<RadioButton
                                  key={index}
                                  name="desktop_pos"
                                  label={desktopPoistion.lable}
                                  checked={desktop_pos === desktopPoistion.value}
                                  onChange={() => this.handleChanageFormValue(desktopPoistion.value, "desktop_pos")}
                                />)
                              }, this)}

                            </Stack>
                            <br />
                            <Stack vertical>
                              <TextField
                                label="Height offset"
                                suffix="px"
                                type="number"
                                value={this.toString(this.state.settings.desktop_height)}
                                onChange={(val) => this.handleChanageFormValue(val, "desktop_height")}
                              />
                              <TextField
                                label="Edge offset"
                                type="number"
                                value={this.toString(this.state.settings.desktop_edge)}
                                onChange={(val) => this.handleChanageFormValue(val, "desktop_edge")}
                              />

                            </Stack>
                          </Card.Section>
                        </Card>

                      </Stack>

                      <Checkbox checked={custom_chat_button_height_status}
                        label="Set custom height for chat icon in product pages"
                        onChange={(val) => this.handleChanageFormValue(val, "custom_chat_button_height_status")}
                      ></Checkbox>


                      {custom_chat_button_height_status ?
                        <Stack distribution="fill">

                          <Card>
                            <Card.Section subdued >
                              <TextStyle variation="strong">Mobile</TextStyle>
                            </Card.Section>
                            <Card.Section >
                              <Stack vertical>
                                <TextField
                                  label="Height offset"
                                  onChange={() => { }}
                                  type="number"
                                  suffix="px"
                                  value={this.toString(this.state.settings.custom_mobile_height)}
                                  onChange={(val) => this.handleChanageFormValue(val, "custom_mobile_height")}
                                />
                              </Stack>
                            </Card.Section>
                          </Card>

                          <Card>
                            <Card.Section subdued >
                              <TextStyle variation="strong">Desktop</TextStyle>
                            </Card.Section>
                            <Card.Section >
                              <Stack vertical>
                                <TextField
                                  label="Height offset"
                                  onChange={() => { }}
                                  type="number"
                                  suffix="px"
                                  value={this.toString(this.state.settings.custom_desktop_height)}
                                  onChange={(val) => this.handleChanageFormValue(val, "custom_desktop_height")}
                                />

                              </Stack>
                            </Card.Section>
                          </Card>
                        </Stack>
                        : null}

                    </FormLayout>
                  </Stack>
                </Card>
              </Layout.AnnotatedSection>
              <PageSelection
                title="Pages to display"
                description="Select which pages to display the WhatsApp Share button."
                pages={this.state.settings.pages}
                selected={this.state.settings.selected_page}
                onChange={this.handleChangePageSection}
              ></PageSelection>


            </Form>
            <SupportBanner></SupportBanner>
          </Layout>
        )}
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  waChatData: state.waChatSettings.data,
  loading: state.waChatSettings.loading,
  error: state.waChatSettings.error,
});

const mapDispatchToProps = (dispatch) => ({
  getStart: (data) => dispatch(getDataStart(data)),
  postStart: (payload) => dispatch(postDataStart(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WaChatSettings);
