import { takeLatest, put } from "redux-saga/effects";
import Api from "../../apis/Api";
import { setLoading } from "../wa-chat/waChatSettingsAction";
import { getAbandonedTxtMsgConfigDataEnd,getAbondonedConfigDataEnd,
        postAbandonedConfigDataEnd,postAbandonedTxtMsgConfigDataEnd } from "./abandonedConfigAction";

import {openToast} from "./../../redux/toast/toastActions";
import { toastObject } from "../../helpers/appHelpers";

let toast = toastObject;


//Abandoned Cart 
function* fetchAbandonedConfigData(data) {
  try {
    const response = yield Api.fetchAsync(
      Api.getAbandonedCrmConfigData,
      data.payload
    );
    yield put(setLoading(true));
    yield put(getAbondonedConfigDataEnd(response));
  } catch (e) {
    yield put(setLoading(false));
  }
}

function* postAbandonedConfigData(data){
  let message = "";
  let isError = false;
  try {
    isError = false;   
    message = "Settings has been saved successfully";
    const response = yield Api.postAsync(
      Api.postAbandonedConfigData,
      data.payload.shopify_store_id,
      data.payload
    );
    yield put(setLoading(true));
    yield put(postAbandonedConfigDataEnd(response));
  } catch (e) {
    message = "There is something want wrong to save settings.";
    isError = true;
  }finally {
    toast = { ...toast, message: message, isActive: true, isError: isError }
    yield put(openToast(toast));   
    data.payload.callback();
  }
}

//Txt MSG Abandoned Config
function* fetchTxtMsgAbandonedConfigData(data) {
  try {
    const response = yield Api.fetchAsync(
      Api.getTxtMsgAbandonedCrmConfigData,
      data.payload
    );
    yield put(setLoading(true));
    yield put(getAbandonedTxtMsgConfigDataEnd(response));
  } catch (e) {
    yield put(setLoading(false));
  }
}
function* postTxtMsgAbandonedConfigData(data){
  let message = "";
  let isError = false;
  try {
    isError = false;   
    message = "Settings has been saved successfully";
    const response = yield Api.postAsync(
      Api.postTxtMsgAbandonedCrmConfigData,
      data.payload.shopify_store_id,
      data.payload
    );
    yield put(setLoading(true));
    yield put(postAbandonedTxtMsgConfigDataEnd(response));
  } catch (e) {
    message = "There is something want wrong to save settings.";
    isError = true;
  }finally {
    toast = { ...toast, message: message, isActive: true, isError: isError }
    yield put(openToast(toast));   
    data.payload.callback();
  }
}
  

function* orderConfigSaga() {
    //GET METHOD
    yield takeLatest("GET_ABANDONED_CONFIG_DATA_START", fetchAbandonedConfigData);
    yield takeLatest("GET_ABANDONED_TXT_MSG_CONFIG_DATA_START", fetchTxtMsgAbandonedConfigData);
    //POST METHOD
    yield takeLatest("POST_ABANDONED_CONFIG_DATA_START", postAbandonedConfigData);
    yield takeLatest("POST_ABANDONED_TXT_MSG_CONFIG_DATA_START", postTxtMsgAbandonedConfigData);
  }
  
export default orderConfigSaga;