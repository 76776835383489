import { takeLatest, put } from "redux-saga/effects";
import Api from "../../apis/Api";
import { setLoading } from "../wa-chat/waChatSettingsAction";
import { getOrderConfigDataEnd, postOrderConfigDataEnd,
        getOrderTxtMsgConfigDataEnd,postOrderTxtMsgConfigDataEnd} from "./orderConfigAction";

import {openToast} from "./../../redux/toast/toastActions";
import { toastObject } from "../../helpers/appHelpers";

let toast = toastObject;

//Wa Order Config
function* fetchOrderConfigData(data) {
    try {
      const response = yield Api.fetchAsync(
        Api.getOrderCrmConfigData,
        data.payload
      );
      yield put(setLoading(true));
      yield put(getOrderConfigDataEnd(response));
    } catch (e) {
      yield put(setLoading(false));
    }
}

function* postOrderConfigData(data) {
  let message = "";
  let isError = false;
  try {
    isError = false;   
    message = "Settings has been saved successfully";
    const response = yield Api.postAsync(
      Api.postOrderConfigData,
      data.payload.shopify_store_id,
      data.payload
    );
    yield put(setLoading(true));
    yield put(postOrderConfigDataEnd(response));
  } catch (e) {
    message = "There is something want wrong to save settings.";
    isError = true;
  }finally {
    toast = { ...toast, message: message, isActive: true, isError: isError }
    yield put(openToast(toast));   
    data.payload.callback();
  }

}


//Txt MSG Order Config
function* fetchTxtMsgOrderConfigData(data) {
  try {
    const response = yield Api.fetchAsync(
      Api.getTxtMsgOrderCrmConfigData,
      data.payload
    );
    yield put(setLoading(true));
    yield put(getOrderTxtMsgConfigDataEnd(response));
  } catch (e) {
    yield put(setLoading(false));
  }
}
function* postTxtMsgOrderConfigData(data){
  let message = "";
  let isError = false;
  try {
    isError = false;   
    message = "Settings has been saved successfully";
    const response = yield Api.postAsync(
      Api.postTxtMsgAbandonedCrmConfigData,
      data.payload.shopify_store_id,
      data.payload
    );
    yield put(setLoading(true));
    yield put(postOrderTxtMsgConfigDataEnd(response));
  } catch (e) {
    message = "There is something want wrong to save settings.";
    isError = true;
  }finally {
    toast = { ...toast, message: message, isActive: true, isError: isError }
    yield put(openToast(toast));   
    data.payload.callback();
  }
}


function* orderConfigSaga() {
    //GET METHOD
    yield takeLatest("GET_ORDER_CONFIG_DATA_START", fetchOrderConfigData);
    yield takeLatest("GET_ORDER_TXT_MSG_CONFIG_DATA_START", fetchTxtMsgOrderConfigData);
    //POST METHOD
    yield takeLatest("POST_ORDER_CONFIG_DATA_START", postOrderConfigData);
    yield takeLatest("POST_ORDER_TXT_MSG_CONFIG_DATA_START", postTxtMsgOrderConfigData);
  }
  
export default orderConfigSaga;