import { GET_DASHBOARD_ORDER_DATA_END, GET_DASHBOARD_ORDER_DATA_START, GET_DASHBOARD_SHIPPING_DATA_START, GET_DASHBOARD_SHIPPING_DATA_END,
    ERROR_MESSAGE_LOG_DATA, GET_ORDER_MESSAGES_DATA_END, GET_ORDER_MESSAGES_DATA_START, GET_SHIPPING_MESSAGES_DATA_END, GET_SHIPPING_MESSAGES_DATA_START } from "./messagesTypes";

const initialState = {
    orderLogs: [],
    shippingLogs: [],
    loading: true,
    error: "",
};

export default function dashboardReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ORDER_MESSAGES_DATA_START: {
            return { ...state, loading: true, error: "" };
        }
        case GET_ORDER_MESSAGES_DATA_END: {
            return { ...state, orderLogs: action.payload, loading: false };
        }
        case GET_SHIPPING_MESSAGES_DATA_START: {
            return { ...state, loading: true, error: " " };
        }
        case GET_SHIPPING_MESSAGES_DATA_END: {
            return { ...state, shippingLogs: action.payload, loading: false };
        }
        case ERROR_MESSAGE_LOG_DATA: {
            return { ...state, loading: false, error: action.error };
        }
        case GET_DASHBOARD_ORDER_DATA_START: {
            return { ...state, loading: true, error: "" };
        }
        case GET_DASHBOARD_ORDER_DATA_END: {
            return { ...state, dashboardData: action.payload, loading: false };
        }
        case GET_DASHBOARD_SHIPPING_DATA_START: {
            return { ...state, loading: true, error: "" };
        }
        case GET_DASHBOARD_SHIPPING_DATA_END: {
            return { ...state, dashboardData: action.payload, loading: false };
        }
        default: {
            return state;
        }
    }
}
