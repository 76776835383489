import { GET_ORDER_CONFIG_DATA_END, GET_ORDER_CONFIG_DATA_START, POST_ORDER_CONFIG_DATA_END, 
    POST_ORDER_CONFIG_DATA_START,
    SET_LOADING,GET_ORDER_TXT_MSG_CONFIG_DATA_START,GET_ORDER_TXT_MSG_CONFIG_DATA_END,
    POST_ORDER_TXT_MSG_CONFIG_DATA_START,POST_ORDER_TXT_MSG_CONFIG_DATA_END} from "./orderConfigTypes";

export const setLoading = (name) => ({
    type: SET_LOADING,
    payload: name,
});

//Order Config
export const getOrderConfigDataStart = (data) => ({
    type: GET_ORDER_CONFIG_DATA_START,
    payload: data,
});

export const getOrderConfigDataEnd = (data) => ({
    type: GET_ORDER_CONFIG_DATA_END,
    payload: data,
});

export const postOrderConfigDataStart = (data) => ({
    type: POST_ORDER_CONFIG_DATA_START,
    payload: data,
});

export const postOrderConfigDataEnd =   (name) => ({
    type: POST_ORDER_CONFIG_DATA_END,
    payload: name,
});


//Text Message Order Settings
export const getOrderTxtMsgConfigDataStart = (data) => ({
    type: GET_ORDER_TXT_MSG_CONFIG_DATA_START,
    payload: data,
});
export const getOrderTxtMsgConfigDataEnd = (data) => ({
    type: GET_ORDER_TXT_MSG_CONFIG_DATA_END,
    payload: data,
});
export const postOrderTxtMsgConfigDataStart = (data) => ({
    type: POST_ORDER_TXT_MSG_CONFIG_DATA_START,
    payload: data,
});
export const postOrderTxtMsgConfigDataEnd =   (name) => ({
    type: POST_ORDER_TXT_MSG_CONFIG_DATA_END,
    payload: name,
});
