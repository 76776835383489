import { SkeletonPage } from '@shopify/polaris'
import React, { Component } from 'react'

export default class FourZeroFour extends Component {
    render() {
        return (
            <div>
                <SkeletonPage>
                    404
                </SkeletonPage>
            </div>
        )
    }
}
