import { OrdersMajor } from "@shopify/polaris-icons";
export const ThemeData = {
	colors: {
		topBar: {
			background: "#1C2260",
		},
	},
	logo: {
		width: 124,
		topBarSource:
			"https://cdn.shopify.com/s/files/1/0558/6406/6212/files/wa_chatshare_logo.png?v=1701088909",
		contextualSaveBarSource:
			"https://cdn.shopify.com/s/files/1/0558/6406/6212/files/wa_chatshare_logo.png?v=1701088909",
		url: "/dashboard",
		accessibilityLabel: "Whatsapp Chat and Share",
	}
};

export const NavData = [
	{
		title: "CRM",
		items: [
			{
				url: "/crm/orders",
				label: "Orders",
				icon: OrdersMajor,
				selected: "true",
			},
			{
				url: "/crm/shipping",
				label: "Shipping",
				icon: OrdersMajor,
			},
			{
				url: "/crm/abandoned-cart",
				label: "Abandoned Cart",
				icon: OrdersMajor,
				disabled: "true",
			},
		],
	},
	{
		title: "Display Settings",
		separator: "true",
		items: [
			{
				url: "/settings/wa-chat",
				label: "Whatsapp Chat",
				icon: OrdersMajor,
			},
			{
				url: "/settings/wa-share",
				label: "Whatsapp Share",
				icon: OrdersMajor,
			},
		],
	},
	{
		title: "CRM Settings",
		separator: "true",
		fill: "true",
		items: [
			{
				url: "/crm/orders",
				label: "Orders",
				icon: OrdersMajor,
			},
			{
				url: "/crm/shipping",
				label: "Shipping",
				icon: OrdersMajor,
			},
		],
	},
	{
		title: "Help & Support",
		separator: "true",
		items: [
			{
				url: "/support/faqs",
				label: "FAQs",
				icon: OrdersMajor,
			},
			{
				url: "/shopify/billing",
				label: "Pricing",
				icon: OrdersMajor,
			},
			{
				url: "/support/email",
				label: "Contact Us",
				icon: OrdersMajor,
			},
		],
	},
];
