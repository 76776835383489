import waChatSettingsTypes from "./waChatSettingsTypes";

export const setLoading = (name) => ({
  type: waChatSettingsTypes.SET_LOADING,
  payload: name,
});

export const getDataStart = (data) => ({
  type: waChatSettingsTypes.GET_WA_CHAT_CONFIG_DATA_START,
  payload: data,
});
export const getDataEnd = (data) => ({
  type: waChatSettingsTypes.GET_WA_CHAT_CONFIG_DATA_END,
  payload: data,
});

export const postDataStart = (data) => ({
  type: waChatSettingsTypes.POST_WA_CHAT_CONFIG_DATA_START,
  payload: data,
});
export const postDataEnd = (data) => ({
  type: waChatSettingsTypes.POST_WA_CHAT_CONFIG_DATA_END,
  payload: data,
});
