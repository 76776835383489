import {
	POST_BILLING_APPROVAL_START,
	POST_BILLING_APPROVAL_END,
	GET_STORE_DETAIL_START,
	GET_STORE_DETAIL_END,
	SET_LOADING,
} from "./authTypes";

export const setLoading = (name) => ({
	type: SET_LOADING,
	payload: name,
});

export const authBillingStart = (data) => ({
	type: POST_BILLING_APPROVAL_START,
	payload: data,
});

export const authBillingEnd = (data) => ({
	type: POST_BILLING_APPROVAL_END,
	payload: data,
});

export const getAuthStoreDetailStart = (data) => ({
	type: GET_STORE_DETAIL_START,
	payload: data,
});

export const getAuthStoreDetailEnd = (data) => ({
	type: GET_STORE_DETAIL_END,
	payload: data,
});
