const setJwttoken = (token) => {
	if (token) {
		localStorage.setItem("jwt_token", token);
	} else {
		localStorage.removeItem("jwt_token");
	}
};

const getJwttoken = () => {
	return localStorage.getItem("jwt_token");
};

export { setJwttoken, getJwttoken };
