import { Banner, Button, TopBar } from "@shopify/polaris";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from '@shopify/polaris';
import { toggleMobileNavigation } from "../../../redux/theme/themeActions";
import { ProductReturnsMinor } from "@shopify/polaris-icons";
// import Nav from "./Nav";

class AppTopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  storeName = () => {

    if (this.props.userData) {
      const storeUrl = "https://" + this.props.userData.myShopfiyUrl + "/admin/";

      return (
        <TopBar.UserMenu
          name={this.props.userData ? this.props.userData.ownerName : null}
          detail={
            <Link
              url={storeUrl}
              external
            >
              {this.props.userData.name}
            </Link>
          }
          initials="Whatsapp Chat and Share"
        />
      );
    }
  }

  render() {
    return <TopBar
      userMenu={
        this.storeName()
      }
      showNavigationToggle="true"
      onNavigationToggle={this.props.onNavigationToggle}
    ></TopBar >;
  }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(AppTopBar);
