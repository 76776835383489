import React, { Component } from "react";
import {
  Page,
  Layout,
  Card,
  Stack,
  Button,
  RadioButton,
  Checkbox,
  FormLayout,
  TextField,
  RangeSlider,
  TextStyle,
  SettingToggle,
  SkeletonBodyText,
} from "@shopify/polaris";
import PropTypes from "prop-types";

class ChatCalloutCard extends Component {

  constructor() {
    super();
    this.state = {
      callout_card_text: "",
      callout_card_display: false,
      card_delay: 0
    }
  }

  componentDidMount() {
    const { callout_card_text, callout_card_display, card_delay } = this.props;
    this.setState({
      callout_card_text: callout_card_text,
      callout_card_display: callout_card_display,
      card_delay: card_delay
    });
  }

  handleChanageFormValue = (value, name) => {
    this.setState({ [name]: value });
    this.props.onChange(this.state)
  }

  render() {
    return (
      <Layout.AnnotatedSection
        title="Chat Callout Card"
        description="Chat Callout card makes the chat button more noticeable to new visitors of your store. To ensure a smooth non-obtrusive experience, callout card is displayed only once per day. Open your store in incognito browser if you are testing changes."
      >
        {this.props.loading ? (
          <SkeletonBodyText />
        ) : (
            <Card sectioned>
              <Stack vertical>
                <FormLayout>
                  <p>
                    <Checkbox
                      label="Show callout card above chat button"
                      checked={this.state.callout_card_display}
                      onChange={(val) => this.handleChanageFormValue(val, "callout_card_display")}
                    />
                  </p>
                  <TextField label="Callout Card Text"
                    value={this.state.callout_card_text}
                    onChange={(val) => this.handleChanageFormValue(val, "callout_card_text")}
                  />
                  <RangeSlider
                    label="Callout Card Delay"
                    value={this.state.card_delay}
                    onChange={(val) => this.handleChanageFormValue(val, "card_delay")}
                    output
                    min={1}
                    max={20}
                    suffix={<p>seconds</p>}
                  />
                </FormLayout>
              </Stack>
            </Card>
          )}
      </Layout.AnnotatedSection>
    );
  }
}

ChatCalloutCard.propTypes = {
  callout_card_text: PropTypes.string,
  callout_card_display: PropTypes.bool,
  card_delay: PropTypes.any,
  onChange: PropTypes.func
};

ChatCalloutCard.defaultProps = {
  callout_card_text: "",
  callout_card_display: false,
  card_delay: 0,
  onChange: () => { }
};

export default ChatCalloutCard;