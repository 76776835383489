import waShareSettingsTypes from "./waShareSettingsTypes";

export const setLoading = (name) => ({
  type: waShareSettingsTypes.SET_LOADING,
  payload: name,
});

export const getShareConfigDataStart = (data) => ({
  type: waShareSettingsTypes.GET_WA_SHARE_CONFIG_DATA_START,
  payload: data
});
export const getShareConfigDataEnd = (data) => ({
  type: waShareSettingsTypes.GET_WA_SHARE_CONFIG_DATA_END,
  payload: data,
});

export const postShareConfigDataStart = (data) => ({
  type: waShareSettingsTypes.POST_WA_SHARE_CONFIG_DATA_START,
  payload: data,
});
export const postShareConfigDataEnd = (name) => ({
  type: waShareSettingsTypes.POST_WA_SHARE_CONFIG_DATA_END,
  payload: name,
});

export const getSectionPagesStart = () => ({
  type: waShareSettingsTypes.GET_PAGES_START,
});
export const getSectionPagesEnd = (data) => ({
  type: waShareSettingsTypes.GET_PAGES_END,
  payload: data,
});

