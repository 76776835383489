import {
  Page,
  Layout,
  Card,
  Stack,
  RadioButton,
  FormLayout,
  TextStyle,
  SettingToggle,
  SkeletonBodyText,
  ChoiceList,
  Form,
  ContextualSaveBar,
} from "@shopify/polaris";
import React, { Component } from "react";
import {
  getShareConfigDataStart,
  postShareConfigDataStart,
  getSectionPagesStart
} from "../../../redux/wa-share/waShareSettingsAction";
import PageSelection from "../../components/common/PageSelection";
import SupportBanner from "../../components/common/SupportBanner";
import { connect } from "react-redux";
import TextColorPicker from "./module/TextColorPicker";
// import { usePrompt } from 'react-router-dom';

class WAShareSettings extends Component {
  constructor() {
    super();
    this.state = {
      share_status: false,
      color_type: false,
      bg_color: "#31f219",
      gradient_color: "#31f219",
      icon_or_text_color: null,
      button_text_color: "#000000",
      selected_page: [],
      error: "",
      is_dirty: false,
      is_loading: false,
      pages: []
    };
  }
  componentDidMount() {
    let { getStart, userData } = this.props;
    // this.setState({userData});
    const storeId = userData.id;
    getStart({ storeId });
    // getPages();
  }

  getSelectedPages() {
    // let;
  }

  handleSubmit = (_event) => {
    this.setState({ is_loading: !this.state.is_loading });
    const waShareSettingsData = {
      status: this.state.share_status,
      color_type: this.state.color_type,
      bg_color: this.state.bg_color,
      gradient_color: this.state.gradient_color,
      icon_or_text_color: this.state.icon_or_text_color,
      button_text_color: this.state.button_text_color,
      position_desktop: this.state.position_desktop,
      position_mobile: this.state.position_mobile,
      selected_page: this.state.selected_page || []
    };

    let { postStart, userData } = this.props;
    const storeId = userData.id;
    postStart({
      storeId,
      waShareSettingsData,
      callback: () => {
        this.setState({ is_loading: false, is_dirty: false });
      }
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.setState({ error: nextProps.error });
    }
    if (nextProps.waShareSettingsData && nextProps.waShareSettingsData.data) {
      const is_dirty = false;
      const {
        status,
        color_type,
        bg_color,
        gradient_color,
        icon_or_text_color,
        button_text_color,
        selected_page,
        error,
        position_desktop,
        position_mobile,
      } = nextProps.waShareSettingsData.data;
      const pages = [];

      if (nextProps.waShareSettingsData.data.pages) {
        nextProps.waShareSettingsData.data.pages.map(function (item) {
          let disabled = false;
          if (item.name.includes('Cart') || item.name.includes('Account')) {
            disabled = true;
          }
          pages.push({ value: item.id, label: item.name, disabled: disabled },)
        });
      }

      this.setState({
        share_status: status,
        color_type: color_type,
        bg_color: bg_color,
        gradient_color,
        icon_or_text_color,
        button_text_color,
        selected_page,
        error,
        position_desktop,
        position_mobile,
        is_dirty,
        pages
      });

    }

  }

  isGradient = (color) => {
    return color === "gradient";
  };

  makeDirty = () => {
    const is_dirty = true;
    this.setState({ is_dirty })
  }

  handleColorTypeChange = (e) => {
    this.makeDirty();
    const color_type = this.isGradient(this.state.color_type)
      ? "single"
      : "gradient";
    this.setState({ color_type: color_type });
  };

  handleDesktopPosChange = (value) => {
    this.makeDirty();
    this.setState({ position_desktop: value[0] });
  };
  handleMobilePosChange = (value) => {
    this.makeDirty();
    this.setState({ position_mobile: value[0] });
  };

  handleEnableStatus = (e, a, r) => {
    this.makeDirty();
    const share_statue = !this.state.share_status;
    this.setState({ share_status: share_statue });
  };

  handleChangePageSection = (selected) => {
    this.makeDirty();
    this.setState({ selected_page: selected });
  }

  handleBgColorChange = (value) => {
    this.makeDirty();
    this.setState({ bg_color: value });
  };
  handleGradientColorChange = (value) => {
    this.makeDirty();
    this.setState({ gradient_color: value });
  };


  handleIconColorChange = (value) => {
    this.makeDirty();
    this.setState({ icon_or_text_color: value });
  };


  handleDescriptionColorChange = (value) => {
    this.makeDirty();
    this.setState({ button_text_color: value });
  };

  onDiscard = () => {
    let { getStart, userData } = this.props;
    const storeId = userData.id;
    getStart({ storeId });
    this.setState({ is_dirty: false });
  }


  render() {

    const { loading } = this.props;


    const contentStatus = this.state.share_status ? "Disable" : "Enable";
    const textStatus = this.state.share_status ? "Enabled" : "Disabled";

    return (
      <Page
        fullWidth="true"
        breadcrumbs={[{ content: "Dashboard", url: "/dashboard" }]}
        title="WhatsApp Share"
        subtitle="Settings for WhatsApp share"
        separator
      >

        {loading ? (
          <Layout>
            <Layout.AnnotatedSection title={<SkeletonBodyText />}>
              <Card sectioned>
                <SkeletonBodyText />
              </Card>
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection title={<SkeletonBodyText />}>
              <Card sectioned>
                <SkeletonBodyText />
              </Card>
            </Layout.AnnotatedSection>
          </Layout>
        ) : (
          <Layout>
            <Form onSubmit={this.handleSubmit}>
              {this.state.is_dirty ?
                <ContextualSaveBar
                  message="Unsaved changes"
                  saveAction={{
                    content: "Save Settings",
                    onAction: this.handleSubmit,
                    loading: this.state.is_loading,
                    disabled: !this.state.is_dirty,
                  }}
                  discardAction={{
                    onAction: () => {
                      this.onDiscard();
                      this.setState({ is_dirty: !this.state.is_dirty })
                    },
                  }}
                />
                : null}

              <Layout.AnnotatedSection
                title="Share"
                description="Enable or Disable."
              >
                <SettingToggle
                  action={{
                    content: contentStatus,
                    onAction: this.handleEnableStatus,
                  }}
                  enabled={this.state.share_status}
                >
                  Customers will be able to see share button on respective pages
                  :<TextStyle variation="strong"> {textStatus}</TextStyle>
                </SettingToggle>

              </Layout.AnnotatedSection>
              <Layout.AnnotatedSection
                title="Design"
                description={
                  <TextStyle>
                    Greet your store visitors with with customised title and
                    help text in the greetings widget.
                     <br></br>
                    <br></br>
                    {/* <Card
                        sectioned
                        title={<TextStyle variation="strong">Preview</TextStyle>}
                      >
                       <div className="wa-share-btn-container wa-share-btn-tmpl-regular">
                        <p className="wa-share-btn-cta">Share</p>
                        <div className="wa-share-btn-img wa-share-icon wa-share-mask"></div>
                </div>
                      </Card> */}
                  </TextStyle>
                }
              >
                <Card
                  sectioned
                  title={
                    <TextStyle>
                      You are currently setting custom colours
                    </TextStyle>
                  }
                >
                  <Stack vertical>
                    <FormLayout>
                      <TextStyle>Background colour style</TextStyle>
                      <Stack>
                        <RadioButton
                          name="color_type"
                          label="Single colour"
                          value="single"
                          checked={this.state.color_type === "single"}
                          onChange={this.handleColorTypeChange}
                        ></RadioButton>
                        <RadioButton
                          name="color_type"
                          label="Gradient of two colours"
                          value="gradient"
                          checked={this.isGradient(this.state.color_type)}
                          onChange={this.handleColorTypeChange}
                        ></RadioButton>
                      </Stack>
                      <Stack distribution="fillEvenly">
                        <div>
                          <TextColorPicker
                            title={"Background colour 1"}
                            color={this.state.bg_color}
                            onChange={this.handleBgColorChange}
                          ></TextColorPicker>
                        </div>
                        <div>
                          {this.isGradient(this.state.color_type) ? (
                            <TextColorPicker
                              title={"Background colour 2 (for gradient)"}
                              color={this.state.gradient_color}
                              onChange={this.handleGradientColorChange}
                            ></TextColorPicker>
                          ) : (
                            ""
                          )}
                        </div>
                      </Stack>
                      <Stack distribution="fillEvenly">
                        <div>
                          <TextColorPicker
                            title={"Icon colour"}
                            color={this.state.icon_or_text_color || '#fff'}
                            onChange={this.handleIconColorChange}
                          ></TextColorPicker>
                        </div>
                        <div>
                          <TextColorPicker
                            title={"Button text colour"}
                            color={this.state.button_text_color || '#fff'}
                            onChange={this.handleDescriptionColorChange}
                          ></TextColorPicker>
                        </div>
                      </Stack>
                    </FormLayout>
                  </Stack>
                </Card>
              </Layout.AnnotatedSection>
              <Layout.AnnotatedSection
                title="Display Position"
                description="Change the display position of the chat button to best suit your store’s design."
              >
                <Card sectioned>
                  <Stack vertical>
                    <FormLayout>
                      <TextStyle variation="strong">Button Position</TextStyle>
                      <Stack distribution="fill">
                        <div>
                          <Stack>
                            <ChoiceList
                              title="Mobile"
                              choices={[
                                { label: "None", value: "none" },
                                { label: "Left", value: "left" },
                                { label: "Right", value: "right" },
                              ]}
                              selected={this.state.position_mobile || []}
                              onChange={this.handleMobilePosChange}
                            />
                          </Stack>
                        </div>
                        <div>
                          <Stack>
                            <ChoiceList

                              title="Desktop"
                              choices={[
                                { label: "None", value: "none" },
                                { label: "Left", value: "left" },
                                { label: "Right", value: "right" },
                              ]}
                              selected={this.state.position_desktop || []}
                              onChange={this.handleDesktopPosChange}
                            />
                          </Stack>
                        </div>
                      </Stack>
                    </FormLayout>
                  </Stack>
                </Card>
              </Layout.AnnotatedSection>

              <PageSelection
                title="Pages to display"
                description="Select which pages to display the WhatsApp Share button."
                pages={this.state.pages}
                selected={this.state.selected_page}
                onChange={this.handleChangePageSection}
              ></PageSelection>
              <SupportBanner></SupportBanner>
            </Form>
          </Layout>
        )}

      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  waShareSettingsData: state.waShareSettings.data,
  pages: state.waShareSettings.pages,
  loading: state.waShareSettings.loading,
  error: state.waShareSettings.error,
});

const mapDispatchToProps = (dispatch) => ({
  getStart: (data) => dispatch(getShareConfigDataStart(data)),
  postStart: (data) => dispatch(postShareConfigDataStart(data)),
  getPages: () => dispatch(getSectionPagesStart()),

});

export default connect(mapStateToProps, mapDispatchToProps)(WAShareSettings);
