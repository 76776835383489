import { Button, Link, List, TextContainer, TextField, TextStyle } from '@shopify/polaris';
import React, { Component } from 'react';
import CopyToClipboardElements from '../pages/components/common/CopyToClipboardElements';




export const checkoutGuideStepsObject = [
    {
        title: "OPEN CHECKOUT SETTINGS",
        description: (
            <TextContainer>
                Open your store <Link external url="{storeAdmin}/settings/checkout"> Checkout</Link> settings page.
                <br />
                <img width="100%" src="https://cdn.shopify.com/s/files/1/0558/6406/6212/files/checkout-settings-page.png?v=1619487154"></img>
                <br />
                It will open in a new window so you can refer back to this guide.
            </TextContainer>
        )
    },
    {
        title: "MAKE PHONE FIELD VISIBLE",
        description: (
            <TextContainer>
                Search for <TextStyle variation="strong">Form options</TextStyle> section on the same page. Set <TextStyle variation="strong">Shipping address phone number</TextStyle> as Required or Optional.
                <img width="100%" src="https://cdn.shopify.com/s/files/1/0558/6406/6212/files/checkout-shipping-phone-selection.gif?v=1619487157"></img>
                 We recommend setting it to Required if you selected email-only option in Step 1. Otherwise, we won't get the customer's phone number to send WhatsApp message.
            </TextContainer>
        ),
    },
    {
        title: "OPEN CHECKOUT LANGUAGE SETTINGS",
        description: (
            <TextContainer>
                Search for <TextStyle variation="strong">Checkout Language</TextStyle> section on the same page. Click <TextStyle variation="strong">Manage Checkout Language </TextStyle>.
                <img width="100%" src="https://cdn.shopify.com/s/files/1/0558/6406/6212/files/open-checkout-languaue.gif?v=1619487158"></img>
            </TextContainer>
        ),
    },
    {
        title: "COPY TEXTs for CHECKOUT LABEL",
        description: (
            <TextContainer>
                <TextStyle variation="subdued">Copy the text below to create a compliant marketing checkbox:</TextStyle>.
                <CopyToClipboardElements
                    code="I agree to receive automated Emails, Whatsapp and Text messages at the email and phone number provided. Consent is not a condition to purchase."
                >
                </CopyToClipboardElements>
            </TextContainer>
        ),
    },
    {
        title: "PASTE TEXT IN CHECKBOX LABEL FIELD",
        description: (
            <TextContainer>
                Search for <TextStyle variation="strong">checkout marketing</TextStyle> section on the same page.
                <br></br>
                Then, paste the copied text into the Accept marketing checkbox label field.
                <br></br>
                Prees Save Button
                <img width="100%" src="https://cdn.shopify.com/s/files/1/0558/6406/6212/files/checkout-language-content-update.gif?v=1619487157"></img>
            </TextContainer>
        ),
    },
    {
        title: "COPY TEXTs for PHONE LABEL",
        description: (
            <TextContainer>
                <TextStyle variation="subdued">Copy the text below to update label for Phone field:</TextStyle>.
                <CopyToClipboardElements
                    code="Cell Phone  - exclusive offers ( optional per checkbox above )"
                >
                </CopyToClipboardElements>
            </TextContainer>
        ),
    },
    {
        title: "PASTE TEXT IN BOTH PHONE LABEL FIELD",
        description: (
            <TextContainer>
                Search for <TextStyle variation="strong">Optional phone label</TextStyle> section on the same page.
                <br></br>
                Then, paste the text into the Optional phone label field AND the Phone label field above it.
                <br></br>
                Prees Save Button
                <img width="100%" src="https://cdn.shopify.com/s/files/1/0558/6406/6212/files/checkout-language-phone-label.gif?v=1619487158"></img>
            </TextContainer>
        ),
    },
    {
        title: "Thank you for updating your details",
        description: (
            <TextContainer>
                <TextStyle variation="strong">Almost done!</TextStyle> In case you missed any of the steps, you can click on the "Back" button and complete them.
                <br /><br />
               If you completed all the steps successfully, your checkout page should look similar to this:
                <br />

                <img width="100%" src="https://cdn.shopify.com/s/files/1/0558/6406/6212/files/final-checkout-page.png?v=1619487154"></img>
            </TextContainer>
        ),
    }
]

