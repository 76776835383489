const waChatSettingsTypes = {
  GET_WA_CHAT_CONFIG_DATA_START: "GET_WA_CHAT_CONFIG_DATA_START",
  GET_WA_CHAT_CONFIG_DATA_END: "GET_WA_CHAT_CONFIG_DATA_END",
  ERROR_WA_CHAT_CONFIG_DATA: "ERROR_WA_CHAT_CONFIG_DATA",
  SET_LOADING: "SET_LOADING",
  
  POST_WA_CHAT_CONFIG_DATA_START: "POST_WA_CHAT_CONFIG_DATA_START",
  POST_WA_CHAT_CONFIG_DATA_END: "POST_WA_CHAT_CONFIG_DATA_END",

  GET_PAGES_START: "GET_PAGES_START",
  GET_PAGES_END: "GET_PAGES_END",
};

export default waChatSettingsTypes;
