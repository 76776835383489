import React, { Component } from "react";
import { Layout, Card, Stack, OptionList, TextStyle } from "@shopify/polaris";
import PropTypes from "prop-types";

class PageSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageType: this.props.pageType,
      pages: this.props.pages || [],
      selected: this.props.selected || [],
    }
  }

  handleSelectPage = (selected) => {
    this.setState({ selected: selected });
    this.props.onChange(selected);
  }


  render() {

    let { selected, pages } = this.state;

    // debugger;
    return (
      <Layout.AnnotatedSection
        title={this.props.title}
        description={this.props.description}
      >
        <Card>
          <Card.Section>
            <TextStyle variation="strong">Pages to display Chat Button</TextStyle>
          </Card.Section>

          <Stack vertical>
            <OptionList
              allowMultiple
              onChange={this.handleSelectPage}
              options={pages}
              selected={selected}
            />
          </Stack>
          <br></br>
        </Card>
      </Layout.AnnotatedSection>
    );
  }

}



PageSelection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  selected: PropTypes.array,
  pages: PropTypes.array,
  onChange: PropTypes.func
};

PageSelection.defaultProps = {
  title: "Pages to display",
  description: "Select which pages to display the WhatsApp Share button.",
  active: [],
  pages: [],
  onChange: () => { }
};




export default PageSelection;
