import { takeLatest, put } from "redux-saga/effects";
import Api from "../../apis/Api";
import api from "../../apis/Api";
import {
  getDataEnd,
  postDataEnd,
  setLoading,
} from "./../../redux/wa-chat/waChatSettingsAction";

import {openToast} from "./../../redux/toast/toastActions";
import { toastObject } from "../../helpers/appHelpers";
import waChatSettingsTypes from "./waChatSettingsTypes";

let toast = toastObject;

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchWAChatSettingsData(data) {
  try {

    const response = yield Api.fetchAsync(
      Api.getWAChatSettingsData, 
      data.payload.storeId
    );
      yield put(setLoading(true));
      console.log("I am done");
      console.log(response);
      yield put(getDataEnd(response));
    } catch (e) {
      yield put(setLoading(false));
    }
  }
  
  
function* postWAChatSettingsData(data) {

  let message = "";
  let isError = false;
  try {
    isError = false;   
    message = "Settings has been saved successfully";
    const response = yield Api.postAsync(
      Api.postWAChatSettingsData,
      data.payload.storeId,
      data.payload.postSettingData
    );
    debugger;
    yield put(postDataEnd(response));
  } catch (e) {
    message = "There is something want wrong to save settings.";
    isError = true;
  }finally {
    toast = { ...toast, message: message, isActive: true, isError: isError }
    yield put(openToast(toast));   
    data.payload.callback();
}

}

  function* waChatSettingsSaga() {
    yield takeLatest(waChatSettingsTypes.GET_WA_CHAT_CONFIG_DATA_START, fetchWAChatSettingsData);
    yield takeLatest(waChatSettingsTypes.POST_WA_CHAT_CONFIG_DATA_START, postWAChatSettingsData);
  }
  
  export default waChatSettingsSaga;
  