import { toastTypes } from './toastTypes';

export const openToast = (payload) => ({
    type: toastTypes.OPEN_REQUEST,
    payload: payload
});

export const closeToast = (payload) => ({
    type: toastTypes.CLOSE_REQUEST,
    payload: payload,
});