import { debounce } from 'lodash';
import { _pagination } from './settings';

export const setPagination = function (page = _pagination.page, perpage = _pagination.perpage, totalpages = 0, total = 0) {

    const pagination = _pagination;
    const from = ((page * perpage) - perpage) + 1;
    const to = totalpages === page ? total : perpage * page;
    pagination.hasNext = totalpages > page;
    pagination.hasPrevious =  page > 1;
    pagination.page = page;
    pagination.perpage = perpage;
    let showing = null;
    if (total > 0) {
        showing = `Showing ${from} to ${to} of ${total} entries`
    }
    pagination.showing = showing;
    return pagination;
}

export function debounceEvent(...args) {
    debounceEvent = debounce(...args);
    return e => {
        e.perstist();
        return debounceEvent(e);
    }
}

export function isEmpty(value) {
    if (Array.isArray(value)) {
        return value.length === 0;
    } else {
        return value === '' || value == null;
    }
}

export function disambiguateLabel(key, value) {
    switch (key) {
        case 'countries':
            return value.map((val) => `Country on ${val}`).join(', ');
        default:
            return value;
    }
}

export function getColumns(ObjColumns) {
    const Columns = [];
    ObjColumns.map(function (column) {
        Columns.push(column.name);
    })
    return Columns;
}

export function getColumnFieldName(ObjColumns, index) {
    const column = ObjColumns.filter(function (column, i) {
        if (i === index) return column;
    })[0];

    return column.field;
}

export function getSortableColumns(ObjColumns) {
    const Columns = [];
    ObjColumns.map(function (column) {
        if (!window.cn(column.sortable)) {
            Columns.push(column.sortable);
        } else {
            Columns.push(false);
        }
    })
    return Columns;
}

export const toastObject = {
    message: "",
    isError: false,
    isActive: false
}


export const toStr = (val) => {
    if (val) { return val.toString() } else { return ""; }
  }
