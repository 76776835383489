import { combineReducers } from "redux";
import orderConfigReducer from "./order-config/orderConfigReducer";
import abandonedConfigReducer from "./abandoned-config/abandonedConfigReducer";
import waChatSettingsReducer from "./wa-chat/waChatSettingsReducer";
import waShareSettingsReducer from "./wa-share/waShareSettingsReducer";
import messagesReducer from "./messages/messagesReducer";
import authReducer from "./auth/authReducer";
import toastReducer from "./toast/toastReducer";
import themeReducer from "./theme/themeReducer";

const rootReducer = combineReducers({
	waChatSettings: waChatSettingsReducer,
	waShareSettings: waShareSettingsReducer,
	orderConfigData: orderConfigReducer,
	abandonedConfigData: abandonedConfigReducer,
	messagesData: messagesReducer,
	authData: authReducer,
	toast:toastReducer,
	theme:themeReducer
});

export default rootReducer;
