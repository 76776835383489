import React from 'react';
import { Toast } from '@shopify/polaris';
import { connect } from "react-redux";
import { closeToast } from '../../../redux/toast/toastActions';


class ToastMarkup extends React.Component {
    render() {
        return (
            this.props.isActive ?
                <Toast content={this.props.message} error={this.props.isError} onDismiss={this.props.closeToast} />
                : null
        )
    }
}

const mapStateToProps = (state) => ({
    isActive: state.toast.isActive,
    isError: state.toast.isError,
    message: state.toast.message,
})
const mapDispatchToProps = (dispatch) => ({
    closeToast: (object) => dispatch(closeToast(object))
})

export default connect(mapStateToProps, mapDispatchToProps)(ToastMarkup);
