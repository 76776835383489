import { toastTypes } from "./toastTypes";


const initState = {
    isError: false,
    isActive: false,
    message: ""
}

export default function toastReducer(state = initState, action) {

    switch (action.type) {
        case toastTypes.OPEN_REQUEST:
            return {...state, isActive: true,isError: action.payload.isError,message: action.payload.message};

        case toastTypes.CLOSE_REQUEST:
            return { ...state, isActive: false };
        default:
            return state;
    }

}