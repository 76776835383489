import { 
    GET_DASHBOARD_ORDER_DATA_END, 
    GET_DASHBOARD_ORDER_DATA_START, 
    GET_DASHBOARD_SHIPPING_DATA_END, 
    GET_DASHBOARD_SHIPPING_DATA_START, 
    GET_ORDER_MESSAGES_DATA_END, 
    GET_ORDER_MESSAGES_DATA_START, 
    GET_SHIPPING_MESSAGES_DATA_END, 
    GET_SHIPPING_MESSAGES_DATA_START, SET_LOADING } from "./messagesTypes";

export const setLoading = (name) => ({
    type: SET_LOADING,
    payload: name,
});

export const getOrderMessageDataStart = (days, channelId, page, size) => ({
    type: GET_ORDER_MESSAGES_DATA_START,
    payload: {
        days,
        channelId,
        page,
        size
    }
});

export const getOrderMessageDataEnd = (data) => ({
    type: GET_ORDER_MESSAGES_DATA_END,
    payload: data,
});

export const getShippingMessageDataStart = (days, channelId, page, size) => ({
    type: GET_SHIPPING_MESSAGES_DATA_START,
    payload: {
        days,
        channelId,
        page,
        size
    }
});

export const getShippingMessageDataEnd = (data) => ({
    type: GET_SHIPPING_MESSAGES_DATA_END,
    payload: data,
});

export const getDashboardOrderDataStart = (channelId, startDate, endDate) => ({
    type: GET_DASHBOARD_ORDER_DATA_START,
    payload: {
        channelId,
        startDate,
        endDate
    }
});

export const getDashboardOrderDataEnd = (data) => ({
    type: GET_DASHBOARD_ORDER_DATA_END,
    payload: data,
});

export const getDashboardShippingDataStart = (channelId, startDate, endDate) => ({
    type: GET_DASHBOARD_SHIPPING_DATA_START,
    payload: {
        channelId,
        startDate,
        endDate
    }
});

export const getDashboardShippingDataEnd = (data) => ({
    type: GET_DASHBOARD_SHIPPING_DATA_END,
    payload: data,
});
