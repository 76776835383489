import React from "react";
import { Route } from "react-router-dom";
import Login from "../pages/Login";

const LoginLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Login>
          <Component {...matchProps} />
        </Login>
      )}
    />
  );
};

export default LoginLayoutRoute;
