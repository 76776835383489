import {
  Card,
  Layout,
  Modal,
  Stack,
  Button,
  SkeletonBodyText,
} from "@shopify/polaris";
import React, { Component } from "react";

export default class AgentPopup extends Component {
  render() {
    return (
      <Layout.AnnotatedSection title="Agents" description="Add your agents">
        <Card sectioned>
          {this.props.loading ? (
            <SkeletonBodyText></SkeletonBodyText>
          ) : (
            <Stack vertical>
              <p>Add your agents</p>
              <Button>Add Agents</Button>

              <Modal
                onClose={() => {}}
                title="Export customers"
                primaryAction={{
                  content: "Export customers",
                  onAction: () => {},
                }}
                secondaryActions={[
                  {
                    content: "Cancel",
                    onAction: () => {},
                  },
                ]}
              >
                <Modal.Section>
                  <Stack vertical>
                    <Stack.Item></Stack.Item>
                    <Stack.Item></Stack.Item>
                  </Stack>
                </Modal.Section>
              </Modal>
            </Stack>
          )}
        </Card>
      </Layout.AnnotatedSection>
    );
  }
}
