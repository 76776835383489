//Wa Order Setting
export const GET_ORDER_CONFIG_DATA_START = "GET_ORDER_CONFIG_DATA_START";
export const GET_ORDER_CONFIG_DATA_END = "GET_ORDER_CONFIG_DATA_END";
export const POST_ORDER_CONFIG_DATA_START = "POST_ORDER_CONFIG_DATA_START";
export const POST_ORDER_CONFIG_DATA_END = "POST_ORDER_CONFIG_DATA_END";

//Error/Set Loading
export const ERROR_ORDER_CONFIG_DATA = "ERROR_ORDER_CONFIG_DATA";
export const SET_LOADING = "SET_LOADING";  

//Text Message Order Setting
export const GET_ORDER_TXT_MSG_CONFIG_DATA_START="GET_ORDER_TXT_MSG_CONFIG_DATA_START";
export const GET_ORDER_TXT_MSG_CONFIG_DATA_END="GET_ORDER_TXT_MSG_CONFIG_DATA_END";
export const POST_ORDER_TXT_MSG_CONFIG_DATA_START="POST_ORDER_TXT_MSG_CONFIG_DATA_START";
export const POST_ORDER_TXT_MSG_CONFIG_DATA_END="POST_ORDER_TXT_MSG_CONFIG_DATA_END";

  