import { 
    ERROR_ORDER_CONFIG_DATA,
    //Abandoned Cart
    GET_ABANDONED_CONFIG_DATA_START, 
    GET_ABANDONED_CONFIG_DATA_END,
    POST_ABANDONED_CONFIG_DATA_START,
    POST_ABANDONED_CONFIG_DATA_END,
    //TXT MSG Abandoned
    GET_ABANDONED_TXT_MSG_CONFIG_DATA_START,
    GET_ABANDONED_TXT_MSG_CONFIG_DATA_END,
    POST_ABANDONED_TXT_MSG_CONFIG_DATA_START,
    POST_ABANDONED_TXT_MSG_CONFIG_DATA_END,
} from "./abandonedConfigTypes";

const initialState = {
    data: [],
    loading: true,
    error: "",
};

export default function orderConfigReducer(state = initialState, action) {
    switch (action.type) {
        //Abandoned Cart
        case GET_ABANDONED_CONFIG_DATA_START: {
            return { ...state, loading: true, error: "" };
        }
        case GET_ABANDONED_CONFIG_DATA_END: {
            return { ...state, data: action.payload, loading: false };
        }
        case POST_ABANDONED_CONFIG_DATA_START: {
            return { ...state, data: action.payload, loading: false };
        }
        case POST_ABANDONED_CONFIG_DATA_END: {
            return { ...state, data: action.payload, loading: false };
        }
        
        //Text Msg Abandoned Setting
        case GET_ABANDONED_TXT_MSG_CONFIG_DATA_START: {
            return { ...state, loading: true, error: "" };
        }
        case GET_ABANDONED_TXT_MSG_CONFIG_DATA_END: {
            return { ...state, data: action.payload, loading: false };
        }
        case POST_ABANDONED_TXT_MSG_CONFIG_DATA_START: {
            return { ...state, data: action.payload, loading: false };
        }
        case POST_ABANDONED_TXT_MSG_CONFIG_DATA_END: {
            return { ...state, data: action.payload, loading: false };
        }

        case ERROR_ORDER_CONFIG_DATA: {
            return { ...state, loading: false, error: action.error };
        }

        default: {
            return state;
        }
    }
}
