import React, { Component } from "react";
import {
  Page,
  Layout,
  Card,
  Checkbox,
  TextContainer,
  ContextualSaveBar,
  Form,
  SkeletonBodyText,
  Badge,
  Toast,
} from "@shopify/polaris";
import { connect } from "react-redux";
import SupportBanner from "../../../components/common/SupportBanner";
import { getAbandonedTxtMsgConfigDataStart, postAbandonedTxtMsgConfigDataStart } from "../../../../redux/abandoned-config/abandonedConfigAction";
import { IsEditingAllowedInChannel, orderConfigDisplay, getTopicIdByTopicName, TxtChannelName, getChannelId } from "../../../../constants";
import ConfigSection from "../../../components/pagecomponent/ConfigSection";
import Api from "../../../../apis/Api";
import { isEmpty } from "../../../../helpers/appHelpers";
import CustomVariables from "../../../components/common/CustomVariables";



class TxtOrderSettings extends Component {
  constructor(props) {
    super();
    this.state = {
      activeGuide: false,
      orderPage: true,
      selectedChannelName: TxtChannelName,
      errors: [],
      loading: true,
      is_loading: false,
      isSaving: false,
      is_dirty: false,
      isDiscarding: false,
      crmConfigDTOList: [],
      languages_id: 1,
      //Toast Message
      toast: {
        isError: false,
        isActive: false,
        message: ""
      },
    };
  }

  validate = () => {
    const { crmConfigDTOList } = this.state;
    let errors = [];
    var valid = true;
    //Loop And Iterate Check Errors
    for (let inc = 0; inc < crmConfigDTOList.length; inc++) {
      //Check isFixed Variables
      let configObj = crmConfigDTOList[inc];
      var errorObj = {};
      //Check Include Coupon
      if (configObj.include_coupon) {
        if (!configObj.isFixed) {
          valid = Boolean(configObj.discount_per && configObj.discount_per !== '');
          if (!valid) {
            errorObj["discount_per"] = "Discount percentage cannot be empty";
          }
        } else {
          valid = Boolean(configObj.coupon_code && configObj.coupon_code !== '');
          if (!valid) {
            errorObj["coupon_code"] = "Coupon code cannot be empty";
          }
        }
      }
      errors.push(errorObj);
    }
    this.setState({ errors: errors });
    return valid;

  }

  handleSubmit = async () => {
    if (this.state.is_dirty) {
      const isDiscarding = true, isSaving = true;
      this.setState({ isDiscarding, isSaving });

      const { userData } = this.props;
      let storeId = userData.id;
      const { crmConfigDTOList } = this.state;
      let configObj = {};

      configObj.crmConfigDTO = crmConfigDTOList;
      const response = await Api.postAsync(
        Api.postCrmConfigData,
        storeId,
        configObj
      );
      if (isEmpty(response) || !response.success) {

      }
      else {
        let loading, isSaving, is_dirty, isDiscarding = false;
        const crmConfigDTOList = response.data.crmConfigDTO;
        const responseDTO = response.data;
        this.setState({ crmConfigDTOList, responseDTO, loading, is_dirty, isSaving, isDiscarding });
        this.generateSingleCrmConfigFromArray(this.state.selectedChannelName, "ORDER_PLACED")
        this.generateSingleCrmConfigFromArray(this.state.selectedChannelName, "ORDER_FULFILLED")
      }
      console.log(response);
    }
  }

  handleChanageFormValue = (value, name) => {
    debugger;
    this.setState({ ...this.state, is_dirty: true, [name]: value });
  }

  getStart = async (storeId) => {
    const { userData } = this.props;
    let store_id = userData.id;
    const response = await Api.fetchAsync(
      Api.getCrmConfigData,
      store_id
    );
    if (isEmpty(response) || !response.success) {

    }
    else {
      let loading, isSaving, is_dirty, isDiscarding = false;
      const crmConfigDTOList = response.data.crmConfigDTO;
      const responseDTO = response.data;
      this.setState({ crmConfigDTOList, loading, responseDTO, is_dirty, isSaving, isDiscarding });
      this.generateSingleCrmConfigFromArray(this.state.selectedChannelName, "ORDER_PLACED")
      this.generateSingleCrmConfigFromArray(this.state.selectedChannelName, "ORDER_FULFILLED")
    }
    console.log(response);
  }

  getSingleCrmConfigFromArray = (selectedChannelName, topic) => {
    let topicId = getTopicIdByTopicName(topic)
    let channel_id = getChannelId(selectedChannelName);

    let { crmConfigDTOList, responseDTO, languages_id } = this.state;
    let crmConfigDTODetails = {};
    let crmConfigIndex = -1;
    crmConfigDTOList.map(function (obj, i) {
      if (obj.topic_id === topicId && obj.channel_id === channel_id) {
        crmConfigDTODetails = obj;
        crmConfigIndex = i;
        crmConfigDTODetails.key = i;
      }
    });
    console.log("crmConfigDTODetails :", crmConfigDTODetails)
    return crmConfigDTODetails;
  }

  generateSingleCrmConfigFromArray = (selectedChannelName, topic) => {
    let { crmConfigDTOList, responseDTO, languages_id } = this.state;
    const { userData } = this.props;

    let topicId = getTopicIdByTopicName(topic)
    let channel_id = getChannelId(selectedChannelName);

    let newArray = [];

    if (!isEmpty(crmConfigDTOList)) {
      newArray = crmConfigDTOList.filter(function (crmConfigDTOList) {
        return crmConfigDTOList.topic_id === topicId && crmConfigDTOList.channel_id === channel_id;
      });
    }
    console.log(newArray);
    if (newArray.length == 0) {
      console.log(responseDTO);
      responseDTO.topicDTOS.map(function (topicDTO) {
        return topicDTO.id == topicId
      })

      let topicDto;
      responseDTO.topicDTOS.map(function (obj) {
        if (obj.id == topicId) {
          topicDto = obj;
        }
      });
      // responseDTO.templateTopicDTOS
      let crmConfigDTODetailsTemp = {
        channel_id: channel_id,
        coupon_code: "",
        discount_per: 0,
        include_coupon: false,
        isFixed: false,
        series: topicDto.series,
        status: false,
        store_id: userData.id,
        topic_id: topicId
      };


      var templateTopicsArray = responseDTO.templateTopicDTOS.filter(function (obj) {
        return obj.topic_id === topicId
          && obj.channel_id === channel_id
          && obj.languages_id === languages_id;
      });

      // debugger;
      crmConfigDTODetailsTemp.TemplateMessageSequence = [];
      templateTopicsArray.map(function (obj, i) {
        var templateMessageSequence = {
          channel_id: channel_id,
          crm_config_id: null,
          delay_time: topicDto.processing === "Instant" ? -1 : 120,
          msg_seq: i,
          store_id: userData.id,
          template: obj.template_message,
          topic_id: topicId
        }
        crmConfigDTODetailsTemp.TemplateMessageSequence.push(templateMessageSequence);
      })
      crmConfigDTOList.push(crmConfigDTODetailsTemp);
      const is_dirty = true;
      this.setState({ crmConfigDTOList, is_dirty });
    }
  }


  componentDidMount() {
    const { userData } = this.props;
    const storeId = userData.id;
    this.getStart(storeId);
  }

  onConfigChange = (is_dirty, key, singleCrmConfig) => {
    this.setState({ is_dirty });
    let { crmConfigDTOList } = this.state;
    if (!isEmpty(crmConfigDTOList[key])) {
      crmConfigDTOList[key] = singleCrmConfig;
    }
    this.setState({ crmConfigDTOList, is_dirty })
  }

  onDiscard = () => {
    const { userData } = this.props;
    const storeId = userData.id;
    this.getStart(storeId);
    const isDiscarding = true, isSaving = true;
    this.setState({ isDiscarding, isSaving });

  }

  // shouldComponentUpdate

  render() {
    const { toast, loading } = this.state;

    return (
      <Page
        fullWidth="true"
        breadcrumbs={[{ content: "Dashboard", url: "/dashboard" }]}
        title="Orders"
        subtitle="Settings for Orders"
        titleMetadata={<Badge status="info">Text Message - SMS </Badge>}
        separator
      >
        {this.state.is_dirty ?
          <ContextualSaveBar
            message="Unsaved changes"
            saveAction={{
              content: "Save Settings",
              onAction: this.handleSubmit,
              loading: this.state.isSaving,
              disabled: !this.state.is_dirty,
            }}
            discardAction={{
              loading: this.state.isDiscarding,
              onAction: () => {
                this.onDiscard();
              },
            }}
          />
          : null}

        {!loading ?

          <Form onSubmit={this.handleSubmit}>

            <Layout>
              <Layout.AnnotatedSection
                title="Order Template"
                description={
                  <TextContainer>
                    <p>
                      Send an order confirmation message as soon as a customer
                      places an order on your store.
                </p>
                    <br></br>
                    <p>
                      You can also give customers a coupon code for their next
                      order.
                      <CustomVariables></CustomVariables>
                    </p>
                  </TextContainer>
                }
              >
                {/* Order Template Setting Data */}
                <ConfigSection
                  userData={this.props.userData}
                  key={this.getSingleCrmConfigFromArray(this.state.selectedChannelName, "ORDER_PLACED").key}
                  singleCrmConfig={this.getSingleCrmConfigFromArray(this.state.selectedChannelName, "ORDER_PLACED")}
                  editing_disabled={!IsEditingAllowedInChannel(this.state.selectedChannelName)}
                  errors={this.state.errors}
                  onChange={this.onConfigChange}
                  is_dirty={this.state.is_dirty}
                  selectedChannelName={this.state.selectedChannelName}
                  topicId={getTopicIdByTopicName("ORDER_PLACED")}
                />
                {/* End Order Template Setting Data */}
              </Layout.AnnotatedSection>
              <Layout.AnnotatedSection
                title="Shipping Template"
                description={
                  <TextContainer>
                    <p>
                      Order shipment message is sent when you fulfil an order in the
                      Shopify admin.
                </p>
                    <br></br>
                    <p>
                      Tracking number and URL is taken from your Shopify order data.
                      <CustomVariables></CustomVariables>
                    </p>
                  </TextContainer>
                }
              >
                {/* Shipping Template Setting Data */}
                <ConfigSection

                  userData={this.props.userData}
                  key={this.getSingleCrmConfigFromArray(this.state.selectedChannelName, "ORDER_FULFILLED").key}
                  singleCrmConfig={this.getSingleCrmConfigFromArray(this.state.selectedChannelName, "ORDER_FULFILLED")}
                  editing_disabled={!IsEditingAllowedInChannel(this.state.selectedChannelName)}
                  errors={this.state.errors}
                  onChange={this.onConfigChange}
                  is_dirty={this.state.is_dirty}
                  selectedChannelName={this.state.selectedChannelName}
                  topicId={getTopicIdByTopicName("ORDER_FULFILLED")}
                />
                {/* End Shipping Template Setting Data */}
              </Layout.AnnotatedSection>

              <SupportBanner></SupportBanner>
            </Layout>
          </Form>
          : <Layout>
            <Layout.AnnotatedSection title={<SkeletonBodyText />}>
              <Card sectioned>
                <SkeletonBodyText />
              </Card>
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection title={<SkeletonBodyText />}>
              <Card sectioned>
                <SkeletonBodyText />
              </Card>
            </Layout.AnnotatedSection>
          </Layout>
        }

        {/* Toast */}
        {toast.isActive ? (
          <Toast content={toast.message} error={toast.isError}
            onDismiss={() => {
              let { toast } = this.state;
              toast = { ...toast, ["isActive"]: false };
              this.setState({ toast: toast });
            }}
          />
        ) : null}
        {/* End Toast */}


      </Page>
    );
  }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
  // getStart: (data) => dispatch(getAbandonedTxtMsgConfigDataStart(data)),
  // postStart: (data) => dispatch(postAbandonedTxtMsgConfigDataStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TxtOrderSettings);
