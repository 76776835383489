export const GET_ORDER_MESSAGES_DATA_START = "GET_ORDER_MESSAGES_DATA_START";
export const GET_ORDER_MESSAGES_DATA_END = "GET_ORDER_MESSAGES_DATA_END";
export const GET_SHIPPING_MESSAGES_DATA_START = "GET_SHIPPING_MESSAGES_DATA_START";
export const GET_SHIPPING_MESSAGES_DATA_END = "GET_SHIPPING_MESSAGES_DATA_END";
export const GET_DASHBOARD_ORDER_DATA_START = "GET_DASHBOARD_ORDER_DATA_START";
export const GET_DASHBOARD_ORDER_DATA_END = "GET_DASHBOARD_ORDER_DATA_END";
export const GET_DASHBOARD_SHIPPING_DATA_START = "GET_DASHBOARD_SHIPPING_DATA_START";
export const GET_DASHBOARD_SHIPPING_DATA_END = "GET_DASHBOARD_SHIPPING_DATA_END";
export const ERROR_MESSAGE_LOG_DATA = "ERROR_MESSAGE_LOG_DATA";
export const SET_LOADING = "SET_LOADING";  
  