import React from 'react';
import { Modal } from '@shopify/polaris';
import { checkoutGuideStepsObject } from '../../../../helpers/checkout-guide-steps';
import PropTypes from "prop-types";

const guideSteps = checkoutGuideStepsObject;
class CheckoutGuideSteps extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: props.userData,
            active: false,
            steps: [],
            activeStep: 0,
            storeName: "",
            storeUrl: ""
        }
    }

    componentDidMount() {
        const { active } = this.props;
        const { userData } = this.props;
        if (userData && userData.name && userData.myShopfiyUrl) {
            const storeName = userData.name;
            const storeUrl = "https://" + this.props.userData.myShopfiyUrl + "/admin/";
            this.setState({ storeName });
            this.setState({ storeUrl });
        }
        this.setState({ active: active });
    }

    componentWillReceiveProps(newProps) {
        this.setState({ active: newProps.active });
    }

    nextStep = () => {
        let activeStep = this.state.activeStep;
        activeStep++
        if (guideSteps.length > activeStep) {
            this.setState({ activeStep: activeStep });
        } else if (guideSteps.length === activeStep) {
            this.onClose();
        }
    }
    onClose = () => {
        this.setState({ active: this.state.active });
        this.props.onClose();
    }

    backStep = () => {
        let activeStep = this.state.activeStep;
        activeStep--
        if (activeStep >= 0) {
            this.setState({ activeStep: activeStep });
        }
    }



    render() {
        const { activeStep } = this.state;
        let stepTitle = `Final step`;
        let primaryBtnContent = "I have completed all the steps";
        if ((activeStep + 1) !== guideSteps.length) {
            primaryBtnContent = "I completed this step";
            stepTitle = `Step ${activeStep + 1} of ${guideSteps.length}`;
        }

        let description = guideSteps[activeStep].description;
        stepTitle = stepTitle + " - " + guideSteps[activeStep].title;
        // description.replace("{storeAdmin}", this.state.myShopfiyUrl);
        return (<Modal
            large={true}
            open={this.state.active}
            onClose={this.onClose}
            title={stepTitle}
            primaryAction={{
                content: primaryBtnContent,
                onAction: this.nextStep,
            }
            }
            secondaryActions={
                [
                    {
                        content: 'Back',
                        onAction: this.backStep,
                    },
                ]}
        >
            <Modal.Section>
                {description}
            </Modal.Section>
        </Modal>
        );
    }
}


CheckoutGuideSteps.propTypes = {
    active: PropTypes.bool,
    onClose: PropTypes.func
};

CheckoutGuideSteps.defaultProps = {
    active: false,
    onChange: () => { }
};


export default CheckoutGuideSteps;