import { Banner, Layout } from "@shopify/polaris";
import React, { Component } from "react";
import { supportUrl } from "../../../constants";

export default class SupportBanner extends Component {
  render() {
    return (
      <Layout.Section>
        <br></br>
        <Banner
          action={{
            content: "Support",
            url: supportUrl,
          }}
          status="info"
        >
          <p>For any support related queries, please contact our team.</p>
        </Banner>
      </Layout.Section>
    );
  }
}
