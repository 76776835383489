import { Button, Stack, TextField, TextStyle } from '@shopify/polaris'
import React, { Component } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';


export default class CopyToClipboardElements extends Component {
    constructor() {
        super();
        this.state = {
            value: '',
            copied: false,
        };
    }

    render() {
        return (
            <Stack alignment="fill" vertical={true}>
                <TextField
                    multiline
                    variation="strong" value={this.props.code}></TextField>
                <Stack vertical={false}>
                    <CopyToClipboard text={this.props.code}
                        onCopy={() => this.setState({ copied: true })}>
                        <Button>Copy to clipboard with span</Button>
                    </CopyToClipboard>
                    {this.state.copied ? <span style={{ color: 'red' }}>Copied.</span> : null}
                </Stack>

            </Stack>

        )
    }
}
