//Wa Abandoned Cart
export const GET_ABANDONED_CONFIG_DATA_START = "GET_ABANDONED_CONFIG_DATA_START";
export const GET_ABANDONED_CONFIG_DATA_END = "GET_ABANDONED_CONFIG_DATA_END";
export const POST_ABANDONED_CONFIG_DATA_START="POST_ABANDONED_CONFIG_DATA_START";
export const POST_ABANDONED_CONFIG_DATA_END = "POST_ORDER_CONFIG_DATA_END";

//Text Message Abandoned Setting
export const GET_ABANDONED_TXT_MSG_CONFIG_DATA_START="GET_ABANDONED_TXT_MSG_CONFIG_DATA_START";
export const GET_ABANDONED_TXT_MSG_CONFIG_DATA_END="GET_ABANDONED_TXT_MSG_CONFIG_DATA_END";
export const POST_ABANDONED_TXT_MSG_CONFIG_DATA_START="POST_ABANDONED_TXT_MSG_CONFIG_DATA_START";
export const POST_ABANDONED_TXT_MSG_CONFIG_DATA_END="POST_ABANDONED_TXT_MSG_CONFIG_DATA_END";

//Error/Set Loading
export const ERROR_ORDER_CONFIG_DATA = "ERROR_ORDER_CONFIG_DATA";
export const SET_LOADING = "SET_LOADING";  



  