import { GET_ABANDONED_CONFIG_DATA_START,GET_ABANDONED_CONFIG_DATA_END,
    SET_LOADING,
    POST_ABANDONED_CONFIG_DATA_START,POST_ABANDONED_CONFIG_DATA_END,
    GET_ABANDONED_TXT_MSG_CONFIG_DATA_START,GET_ABANDONED_TXT_MSG_CONFIG_DATA_END,
    POST_ABANDONED_TXT_MSG_CONFIG_DATA_START,POST_ABANDONED_TXT_MSG_CONFIG_DATA_END } from "./abandonedConfigTypes";

export const setLoading = (name) => ({
    type: SET_LOADING,
    payload: name,
});

//Abandoned Cart
export const getAbandonedConfigDataStart = (data) => ({
    type: GET_ABANDONED_CONFIG_DATA_START,
    payload: data,
});
export const getAbondonedConfigDataEnd = (data) => ({
    type: GET_ABANDONED_CONFIG_DATA_END,
    payload: data,
});

export const postAbandonedConfigDataStart = (data) => ({
    type: POST_ABANDONED_CONFIG_DATA_START,
    payload: data,
});
export const postAbandonedConfigDataEnd =   (name) => ({
    type: POST_ABANDONED_CONFIG_DATA_END,
    payload: name,
});

//Text Message Abandoned Settings
export const getAbandonedTxtMsgConfigDataStart = (data) => ({
    type: GET_ABANDONED_TXT_MSG_CONFIG_DATA_START,
    payload: data,
});
export const getAbandonedTxtMsgConfigDataEnd = (data) => ({
    type: GET_ABANDONED_TXT_MSG_CONFIG_DATA_END,
    payload: data,
});
export const postAbandonedTxtMsgConfigDataStart = (data) => ({
    type: POST_ABANDONED_TXT_MSG_CONFIG_DATA_START,
    payload: data,
});
export const postAbandonedTxtMsgConfigDataEnd =   (name) => ({
    type: POST_ABANDONED_TXT_MSG_CONFIG_DATA_END,
    payload: name,
});

  